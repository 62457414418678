@charset "UTF-8";

/* CSS Document */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
ul,
li {
    list-style-type: none;
}
a, button, input[type=submit]{
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}
a,
img,
a:hover, button {
    text-decoration: none;
    outline: none !important;
}
h1, h2, h3, h4{
    font-weight: normal;
}
img{
    max-width: 100%;
    height: auto;
}
:focus, :hover, :active{
    outline: none !important;
}
body{
    overflow-x: hidden;
}

/* --- CONTAINER --- */

.container, .container-md, .container-sm{
    max-width: 1204px;
}

/* --- END CONTAINER --- */

/* FONTS */

@font-face {
    font-family: 'ITC Avant Garde Gothic';
    src: url('assets/fonts/itc_avant_garde_gothic_bold-webfont.woff2') format('woff2'),
         url('assets/fonts/itc_avant_garde_gothic_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Noto Sans Bold';
    src: url('assets/fonts/notosans-bold-webfont.woff2') format('woff2'),
         url('assets/fonts/notosans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Noto Sans';
    src: url('assets/fonts/notosans-regular-webfont.woff2') format('woff2'),
         url('assets/fonts/notosans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Noto Sans Light';
    src: url('assets/fonts/notosans-light-webfont.woff') format('woff2'),
         url('assets/fonts/notosans-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* --- END FONTS --- */

/* --- COLORS --- */

/* TEXT */

.text-white{
    color: #FFFFFF;
}
.text-black{
    color: #000000;
}
.text-dark-gray{
    color: #0E0E0E;
}
.text-gray{
    color: #C6C6C6;
}
.text-input-gray{
    color: #1A1A1A;
}
.text-magenta{
    color: #FF00AA;
}
.text-purple{
    color: #7800FF;
}
.text-green{
    color: #00FF00;
}
.text-orange{
    color: #FF6A10;
}

/* BACKGROUND */

.bg-white{
    background-color: #FFFFFF;
}
.bg-black{
    background-color: #000000;
}
.bg-dark-gray{
    background-color: #0E0E0E;
}
.bg-gray{
    background-color: #C6C6C6;
}
.bg-input-gray{
    background-color: #1A1A1A;
}
.bg-magenta{
    background-color: #FF00AA;
}
.bg-purple{
    background-color: #7800FF;
}
.bg-green{
    background-color: #00FF00;
}
.bg-orange{
    background-color: #FF6A10;
}

/* BORDER */

.border-white{
    border-color: #FFFFFF;
}
.border-black{
    border-color: #000000;
}
.border-dark-gray{
    border-color: #0E0E0E;
}
.border-gray{
    border-color: #C6C6C6;
}
.border-input-gray{
    border-color: #1A1A1A;
}
.border-magenta{
    border-color: #FF00AA;
}
.border-purple{
    border-color: #7800FF;
}
.border-green{
    border-color: #00FF00;
}
.border-orange{
    border-color: #FF6A10;
}

/* --- END COLORS --- */

/* --- TEXTS --- */

.text{
    font-family: 'Noto Sans Light';
}
.text.bold{
    font-family: 'Noto Sans Bold';
}
.text.itc{
    font-family: 'ITC Avant Garde Gothic';
}

/* SIZE TEXT */

.size-default{
    font-size: 16px;
    line-height: 22px;
}
.size-small{
    font-size: 14px;
}

/* --- END TEXTS --- */

/* --- TITLES --- */

.title{
    font-family: 'ITC Avant Garde Gothic';
}

/* SIZE TITLES */

.title.h1{
    font-size: 50px;
    line-height: 50px;
}
.title.h2{
    font-size: 30px;
}
.title.h3{
    font-size: 20px;
}
.title.h4{
    font-size: 14px;
}

/* --- END TITLES --- */

/* --- BUTTON --- */

/* ACTIONS */

.actions .btn, .actions-modal .btn {
    border-radius: 10px;
    text-transform: uppercase;
    padding: 15px 30px 18px;
    line-height: normal;
    border: 0;
}
.actions .btn:hover, .actions-modal .btn:hover {
    background-color: #7800FF;
}

.actions-modal .btn {
    margin-left: calc(.5 * var(--bs-gutter-x));
}

/* VIEW MORE */

.view-more{
    display: inline-block;
    padding: 0;
    text-transform: uppercase;
}
.view-more:hover{
    color: #7800FF;
}

/* DISCORD */

.btn-discord img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

/* END DISCORD */

/* PLAY */

.btn-play img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

/* END PLAY */

/* --- END BUTTON --- */

/* --- HOME --- */

/* HIGHLIGHT */

.highlight{
    width: 100%;
    height: auto;
    padding: 40px;
    background-image: url(assets/images/pattern-triangles-purple.svg);
    background-size: 650px;
    background-position: calc(100% - 5px) 10px;
    background-repeat: no-repeat;
}

/* CONTENT */

.content-news .news .thumb{
    width: 100%;
    height: 210px;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 0;
}
.content-news .news .thumb img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* END HIGHLIGHT */

/* GAMES */

.games{
    width: 100%;
    height: auto;
    padding: 0 20px 40px;
}

/* CONTENT */

.games.fullscreen .content-games .game{
    margin-bottom: 40px;    
}
.games.fullscreen .content-games .game .content .title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 46px;
}
.games.fullscreen .content-games .game .content p.text{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 66px;
}
.games.fullscreen .content-games .game .thumb{
    width: 100%;
    height: 210px;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 0;
}
.games.fullscreen .content-games .game .thumb img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* END GAMES */

/* TALKS */

.talks{
    width: 100%;
    height: auto;
    padding: 40px;
    background-color: #0E0E0E;
    background-image: url(assets/images/pattern-lines-pink.svg);
    background-size: 100px;
    background-position: 0 bottom;
    background-repeat: no-repeat;
}

/* CONTENT */

.talks .content-talks .talk {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
}
.talks .content-talks .talk .content .title{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 74px;
}
.talks .content-talks .talk .thumb{
    width: 100%;
    height: 210px;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 0;
}
.talks .content-talks .talk .thumb img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* END TALKS */

/* PLAYLIST */

/*.playlist_container {
    max-height: 600px;
}

.playlist_list {
    overflow-y: scroll;
    max-height: 600px;
}

.playlist_item_image {
    border-radius: 30px;
}*/


.playlist_list {
    overflow-y: auto;
}

  .playlist_container, .playlist_list {
    max-height: 440px;
  }

  .playlist_list::-webkit-scrollbar {
    width: 5px;
  }

  .playlist_list::-webkit-scrollbar-track {
    background-color: #0E0E0E;
    border-radius: 10px;
  }

  .playlist_list::-webkit-scrollbar-thumb {
    background-color: #FF6A10;
    border-radius: 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  .playlist_list::-webkit-scrollbar-thumb:hover {
    background-color: #7800FF;
    border-radius: 10px;
  }

  .playlist_list .playlist_item {
    cursor: pointer;
    opacity: 0.5;
    margin: 0 0 20px !important;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
  }

  .playlist_list .playlist_item:hover{
    opacity: 1;
  }

  .playlist_list .playlist_item.active{
    opacity: 1;
  }

  .playlist_list .playlist_item .playlist_item_image {
    width: 100%;
    max-width: 140px;
    height: 90px;
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
    padding: 0;
    margin: 0;
    flex: none;
  }

  .playlist_list .playlist_item .playlist_item_title{
    font-family: "ITC Avant Garde Gothic";
    font-size: 14px;
    line-height: 18px;
  }
  
  .playlist_video .playlist_video_container{
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
  }

  .playlist_video .playlist_video_container iframe{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }




/* END PLAYLIST */

/* --- END HOME --- */

/* --- PAGES --- */

/* HEADER */

.header-page{
    width: 100%;
    height: auto;
    padding: 40px;
    background-image: url(assets/images/pattern-circle-purple.svg);
    background-size: 650px;
    background-position: calc(100% - 5px) 100%;
    background-repeat: no-repeat;
    border-bottom: 1px solid #2C2C2C;
}
.header-page .title{
    font-size: 0.1px !important;
    margin: 0;
    padding: 0;
}
.header-page figure {
    max-width: 400px;
}
.header-page .content-subtitle{
    max-width: 660px;
}

/* END HEADER */

/* BREADCRUMB */

.content-bread {
    border-bottom: 1px solid #2C2C2C;
}
.content-bread .breadcrumb {
    padding: 0;
    background-color: transparent;
    margin: 20px 0;
}
.content-bread .breadcrumb a:hover{
    color: #FF6A10;
}
.content-bread .breadcrumb li:first-of-type::before{
    margin: 0;
    display: none;
}
.content-bread .breadcrumb li::before{
    content: '';
    background-image: url(assets/images/icon-right.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    display: block;
    margin: 5px 15px 0px 10px;
}

/* END BREADCRUMB */

/* FILTER */

.filter-games{
    width: 100%;
    height: auto;
    padding: 40px;
}
.filter-games .order-by{
    width: 100%;
    border-bottom: 1px solid #2C2C2C;
    padding-bottom: 20px;
    margin-bottom: 20px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}

/* END FILTER */

/* SHOW FILTER AND CATEGORIES */

.filter-mobile{
    display: none;
    width: 100%;
    margin-bottom: 20px;
}
.filter-mobile .showFilterMobile,
.filter-mobile .showCategoriesMobile{
    background-color: #FF6A10;
    background-image: url(assets/images/icon-plus.svg);
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    font-size: 0.1px;
    border-radius: 50%;
}
.filter-mobile .showFilterMobile.active,
.filter-mobile .showCategoriesMobile.active{
    background-color: #FF00AA;
    background-image: url(assets/images/icon-minus.svg);
}

/* END SHOW FILTER AND CATEGORIES */

/* CATEGORIES */

.categories .options{
    width: 100%;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}
.categories .options.active{
    border-bottom: 1px solid #2C2C2C;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.categories .options .btn-category {
    display: inline-block;
    border: 1px solid #C6C6C6;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 19px 10px;
    margin: 0 10px 10px 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #C6C6C6;
}
.categories .options .btn-category:hover{
    border: 1px solid #FF6A10;
    color: #FF6A10;
}
.categories .options .btn-category.active{
    background-color: #FF6A10;
    border: 1px solid #FF6A10;
    color: #FFFFFF;
}
.categories .result-filter .result-button{
    background-color: transparent;
    background-image: url(assets/images/icon-delete.svg);
    background-size: 16px;
    background-position: calc(100% - 0px) 2px;
    background-repeat: no-repeat;
    border: 1px solid transparent;
    padding: 0 25px 0 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #C6C6C6;
}
.categories .result-filter .result-button:hover{
    color: #FF00AA;
    border-bottom: 1px solid #FF00AA;
}

/* END CATEGORIES */

/* GAMES LIST */

.game-list .list{
    margin: 0 -10px 0;
}
.game-list .list .card{
    width: 100%;
    max-width: 130px;
    background-color: transparent;
    border: 0;
    margin: 0 10px 20px 10px;
}
.game-list .list .card a{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.game-list .list .card .visual{
    position: relative;
}

/* AGE  */

.age{
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    z-index: 2;
}
.age.color::before{
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}
.age.color.orange::before{
    background-color: rgba(255,106,16,.8);
}
.age.color.blue::before{
    background-color: rgba(0,15,255,.8);
}
.age.color.yellow::before{
    background-color: rgba(247,166,0,.8);
}
.age.color.green::before{
    background-color: rgba(0,150,64,.8);
}
.age.color.black::before{
    background-color: rgba(0,0,0,.8);
}
.age.color p{
    text-align: center;
    position: relative;
    z-index: 1;
    line-height: normal;
    padding: 0 2px 3px;
}

/* END AGE */

.game-list .list .card .visual figure{
    width: 130px;
    height: 130px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;
    z-index: 0;
}
.game-list .list .card .visual figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}
.game-list .list .card a:hover .visual figure img{
    -webkit-transform: scale(1.2) rotate(10deg);
    transform: scale(1.2) rotate(10deg);
}
.game-list .list .card.award .visual:before {
    content: "";
    background-image: url(assets/images/icon-medal.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 1;
    left: calc(50% - 16px);
    bottom: 0;
}
.game-list .list .card .info .title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 44px;
}
.game-list .list .card .info .title,
.game-list .list .card .info p{
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}
.game-list .list .card a:hover .info .title,
.game-list .list .card a:hover .info p{
    color: #FF6A10 !important;
}

/* ADVERTISING */

.advertising{
    width: 100%;
    margin: 20px 0 40px;
}
.advertising:last-of-type{
    margin-bottom: 0;
}
.advertising figure{
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 15px;
    overflow: hidden;
}
.advertising figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* END ADVERTISING */

/* END GAMES LIST */

/* GAME DETAIL */

.game-detail{
    width: 100%;
    height: auto;
    padding: 40px;
    background-image: url(assets/images/pattern-triangles-purple-2.svg);
    background-size: 300px;
    background-position: calc(100% - 5px) 0px;
    background-repeat: no-repeat;
}
.game-detail .about-game > div{
    padding-bottom: 30px;
    margin-bottom: 40px;
    border-bottom: 1px solid #2C2C2C;
}
.game-detail .about-game > div:last-of-type{
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

/* VIDEO */

.game-detail .about-game .video {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    border-radius: 30px;
    margin-bottom: 20px;
}
.game-detail .about-game .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

/* END VIDEO */

/* ABOUT */

.game-detail .about-game .content .pegi > div{
    margin-right: 15px;
}
.game-detail .about-game .content .pegi .age{
    position: relative;
    top: inherit;
    left: inherit;
    width: 32px;
    height: 32px;
}
.game-detail .about-game .content .pegi .award{
    width: 32px;
    height: 32px;
}
.game-detail .about-game .content .award img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.game-detail .about-game .content .about{
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #2C2C2C;
}
.game-detail .about-game .content .about p:last-of-type{
    margin-bottom: 0;
    padding-bottom: 0;
}

/* END ABOUT */

/* SHARE */

.game-detail .about-game .content .share{
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #2C2C2C;
}
.game-detail .about-game .content .share .social span{
    margin-right: 10px;
}
.game-detail .about-game .content .share .social a{
    margin: 0 5px;
}
.game-detail .about-game .content .share .social a:hover{
    transform: scale(1.15);
}
.game-detail .about-game .content .share .like .like-button{
    width: 100%;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
} 
.game-detail .about-game .content .share .like .like-button .title{
    margin-right: 15px;
}
.game-detail .about-game .content .share .like .like-button .icon-like{
    width: 24px;
    height: 24px;
    background-image: url(assets/images/icon-heart-border.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    margin-right: 5px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}
.game-detail .about-game .content .share .like .like-button:hover .icon-like,
.game-detail .about-game .content .share .like.active .like-button .icon-like{
    background-image: url(assets/images/icon-heart-full.svg);
}
.game-detail .about-game .content .share .like .like-button .number-like{
    color: #FF00AA;
    font-size: 14px;
}
.game-detail .about-game .content .share .like .like-message{
    height: 0;
    opacity: 0;
    margin: -3px 0 0 10px;
    pointer-events: none;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}
.game-detail .about-game .content .share .like.active .like-message{
    height: 21px;
    opacity: 1;
    pointer-events: none;
}

/* END SHARE */

/* HOW TO PLAY */

.game-detail .about-game .how-to-play figure{
    max-height: 340px;
    margin: 0 20px 20px 0;
}
.game-detail .about-game .how-to-play figure img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* END HOW TO PLAY */

.socialImage {
    height: 30px;
    color: #FFF;
    fill: #FFF;
    margin: 10px 5px;
}

.showMoreText {
    display: none;
}

/* END GAME DETAIL */

/* --- END PAGES --- */

/* --- FORM --- */

.form-select:focus, :focus, .form-control:focus{
    background-color: #1A1A1A;
    color: #C6C6C6;
    box-shadow: none !important;
    border: 0;
    outline: 0;
}
.form-control::placeholder{
    color: #C6C6C6;
    font-family: 'Noto Sans';
    font-size: 14px;
}
input[type=text], input[type=password], input[type=email], input[type=tel], input[type=number],
.form-select, .form-control, select, textarea {
    width: 100%;
    height: 48px;
    padding: 10px 15px 14px;
    color: #C6C6C6;
    font-family: 'Noto Sans';
    font-size: 14px;
    line-height: normal;
    background-color: #1A1A1A;
    border-radius: 10px;
    border: 0;
    appearance: none;
    -moz-appearance: none;
}
.form-select, select{
    background-color: #1A1A1A;
    color: #C6C6C6;
    background-image: url(assets/images/icon-down.svg);
    --bs-form-select-bg-img: url(assets/images/icon-down.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
}
.react-select__control {
    width: 100%;
    height: 48px;
    border-radius: 10px !important;
    border: 0 !important;
    color: #c6c6c6;
    overflow: hidden;
    background-color: #1a1a1a !important;
  }

  .react-select__control .react-select__single-value {
    color: #c6c6c6;
  }

  .react-select__control .react-select_value-container{
    height: 100%;
    padding: 0 13px;
    flex: 1;
    display: flex;
  }
  .react-select__control .react-selectvalue-container .react-select_placeholder,
  .react-select__control .react-selectvalue-container .react-select_input-container{
    color: #c6c6c6 !important; 
  }
  .react-select__control .react-selectindicators .react-select_indicator-separator{
    background-color: #363636;
  }
  .react-select-container .react-select__menu{
    background-color: #1a1a1a !important;
    color: #c6c6c6 !important;
  }


textarea{
    height: 120px;
    resize: none;
}
.form-check-input[type=checkbox], input[type=checkbox]{
    width: 18px;
    height: 18px;
    background-color: #1A1A1A;
    border: 0;
    margin-right: 10px;
    margin-top: 3px;
}
.form-group a{
    color: #FF6A10;
}
.form-group a:hover{
    color: #7800FF;
}

/* --- END FORM --- */

/* --- CAROUSEL --- */

.carousel-slick .slick-list{
    padding-bottom: 35px;
    margin-bottom: 80px;
}
.carousel-slick .slick-arrow::before{
    content: '' !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 42px;
    height: 42px;
    display: block;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
    opacity: 1;
}
.carousel-slick .slick-arrow{
    width: 42px;
    height: 42px;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    transform: translate(0);
}
.carousel-slick .slick-arrow.slick-prev{
    left: calc(50% - 47px);
}
.carousel-slick .slick-arrow.slick-next{
    left: calc(50% - -5px);
}
.carousel-slick .slick-arrow:hover::before{
    transform: scale(1.1);
    top: 43.5%;
}
.carousel-slick .slick-prev::before{
    background-image: url(assets/images/arrows-left.svg);
}
.carousel-slick .slick-next::before{
    background-image: url(assets/images/arrows-right.svg);
}

/* --- END CAROUSEL --- */

/* --- MODAL --- */

.modal-backdrop {
    opacity: .8 !important;
    background: #7800FF !important;
    --bs-backdrop-bg: #7800FF;
}
.modal-backdrop:nth-last-of-type(2){
    display: none !important;
}
.custom-modal{
    background: rgb(26 26 26 / 80%) !important;
}
.custom-modal .modal-dialog{
    width: 90%;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
}
.custom-modal .modal-dialog .modal-content .btn-close{
    width: 24px;
    height: 24px;
    right: 12px;
    top: 12px;
    position: absolute;
    opacity: 1;
}
.custom-modal .modal-dialog .modal-content .btn-close button{
    background-color: transparent;
    background-image: url(assets/images/icon-delete-white.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    cursor: pointer;
    opacity: 1;
    border: 0;
    text-indent: -99999px;
    -webkit-transition: all .3s;
    -moz-transition: all .3s;
    transition: all .3s;
}
.custom-modal .modal-dialog .modal-content .btn-close:hover button{
    transform: scale(1.15);
}
.custom-modal .modal-dialog .modal-content{
    background:#000;
    padding: 40px;
    border-radius: 30px;
    border: 0;
}

/* ESTANDE */

.custom-modal .modal-dialog .modal-content .content.estande .image{
    position: relative;
    overflow: hidden;
}
.custom-modal .modal-dialog .modal-content .content.estande .image .points {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50px;
    top: 50px;
    z-index: 1;
}
.custom-modal .modal-dialog .modal-content .content.estande .image .points .point {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 15%;
    top: 39%;
}
.custom-modal .modal-dialog .modal-content .content.estande .image .points .point a {
    background-color: #FFFFFF;
    background-image: url(assets/images/icon-plus-black.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    width: 32px;
    height: 32px;
    display: block;
    border-radius: 50%;
    position: relative;
    z-index: 1;
}
.custom-modal .modal-dialog .modal-content .content.estande .image .points .point a:hover{
    background-color: #FF6A10;
    background-image: url(assets/images/icon-plus.svg);
}
.custom-modal .modal-dialog .modal-content .content.estande .image .points .point .efeito{
    position: absolute;
    top: -48px;
    left: -48px;
    border-style: solid;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
    height: 400%;
    width: 400%;
    margin: -0px;
    border-radius: 50%;
    -webkit-animation: pulsar_pulsate 4s ease-out;
    -webkit-animation-iteration-count: infinite;
    -ms-animation: pulsar_pulsate 4s ease-out;
    -ms-animation-iteration-count: infinite;
    animation: pulsar_pulsate 4s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0;
    z-index: 0;
}
.custom-modal .modal-dialog .modal-content .content.estande .image figure{
    width: 100%;
    max-height: 534px;
    border-radius: 20px;
    overflow: hidden;
    margin: 0;
}
.custom-modal .modal-dialog .modal-content .content.estande .image figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* SIGN UP */

.custom-modal .modal-dialog .modal-content .img-cadastro{
    overflow: hidden;
}
.custom-modal .modal-dialog .modal-content .img-cadastro img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}
.custom-modal .modal-dialog .modal-content .img-cadastro .web{
    display: block;
}
.custom-modal .modal-dialog .modal-content .img-cadastro .mob{
    display: none;
}
.custom-modal .modal-dialog .modal-content .content-cadastro {
    /*padding: 0 40px;*/
}
.custom-modal .modal-dialog .modal-content .content-cadastro .form-group{
    margin-bottom: 15px;   
}
.custom-modal .modal-dialog .modal-content .content-cadastro .form-group:last-of-type{
    margin-left: 0;
}

/* END SIGN UP */

/* --- END MODAL --- */

/* --- ANIMATION --- */

/* PIN ESTANDE */

@-webkit-keyframes pulsar_pulsate {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        -webkit-transform: scale(0.5, 0.5);
        opacity: 0.0;
    }
}
@keyframes pulsar_pulsate {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        -webkit-transform: scale(0.5, 0.5);
        opacity: 0.0;
    }
}

/* --- END ANIMATION --- */


/* --- LOGIN --- */

.countdown ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
}
.countdown ul li{
    font-family: "ITC Avant Garde Gothic";
    font-weight: 700;
    font-size: 24px;
    text-transform: uppercase;
    margin-right: 15px;
}
.countdown ul li span{
    color: #ff6910;
}
.content-cadastro form button.btn {
    font-family: "ITC Avant Garde Gothic";
    text-transform: uppercase;
    font-weight: 700;
    padding: 15px 40px;
    background-color: #FF6A10;
    color: #FFFFFF;
    border: 0;
    font-size: 16px;
    margin-right: 20px;
}
.content-cadastro form button.btn:hover{
    background-color: #7800FF;
}
.content-cadastro form a.btn {
    font-family: "ITC Avant Garde Gothic";
    color: #fff;
    font-size: 14px !important;
    padding: 0;
}
.content-cadastro form a.btn:hover{
    background-color: transparent;
    color: #7800FF;
}

/* --- END LOGIN --- */


.text-view-more {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-view-more.active {
    display: block;
}

.btn-view-more {
    margin-top: 10px;
    display: contents;
    text-decoration: none;
    padding: 0;
    border: none;
}
.btn-view-more:hover {
    color: #7800ff !important;
}



/* --- ADS --- */

.ads {
    width: 100%;
    margin: 15px 0 20px;
    background-color: #000;
}
.ads .container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.ads .cols{
    margin: 0 15px;
}
.ads .cols .title-brand {
    height: 20px;
}
.ads .cols .brands {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}
.ads .cols .brands figure {
    margin: 0 25px 0 0;
    width: 120px;
    height: 70px;
}
.ads .cols .brands figure:last-child{
    margin-right: 0;
}
.ads .cols .brands figure img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 982px) {
    .ads .cols{
        width: 100%;
        margin: 10px 0;
    }
    .ads .cols .brands {
        justify-content: center;
    }
    .ads .cols .brands figure {
        margin: 0 20px 0 0;
        width: 90px;
        height: 55px;
    }
    .ads .cols .title-brand {
        text-align: center;
    }
}

/* --- END ADS --- */

/* --- RESPONSIVE --- */

@media (max-width: 1600px) {
    /* --- PAGES --- */

    /* GAME DETAIL */

    .game-detail{
        background-size: 250px;
        background-position: calc(105% - 5px) 0px;
    }

    /* END GAME DETAIL */

    /* --- END PAGES --- */
}
@media (max-width: 1366px) {

    /* --- PAGES --- */

    /* GAME DETAIL */

    .game-detail{
        background-position: calc(112% - 5px) 0px;
    }

    /* END GAME DETAIL */

    /* --- END PAGES --- */

}

@media screen and (max-width: 1280px) {

    .playlist_container {
      max-width: 95%;
      margin: auto;
    }
  
  }
  

@media (max-width: 1200px) {

    /* --- PAGES --- */

    /* HEADER */

    .header-page{
        background-position: calc(160% - 5px) 100%;
    }

    /* END HEADER */

    /* --- END PAGES --- */

}
@media (max-width: 982px) {

    /* --- CONTAINER --- */

    .container, .container-md, .container-sm{
        max-width: 100%;
    }

    /* --- END CONTAINER --- */

    /* --- HOME --- */

    /* HIGHLIGHT */

    .highlight{
        padding: 20px;
        background-image: url(assets/images/pattern-triangles-pink-2.svg);
        background-size: 200px;
    }

    /* END HIGHLIGHT */

    /* GAMES */

    .games{
        padding: 20px;
    }
    
    /* END GAMES */

    /* TALKS */

    .talks{
        padding: 40px 20px;
    }

    /* END TALKS */

    /* --- END HOME --- */

    /* --- PAGES --- */

    /* HEADER */

    .header-page{
        background-image: inherit;
        padding: 40px 20px;
    }
    .header-page .content-subtitle{
        max-width: 100%;
    }

    /* END HEADER */

    /* FILTER */

    .filter-games{
        padding: 40px 20px;
    }
    .filter-games .order-by .row > div{
        margin-bottom: 20px;
    }
    .filter-games .order-by .row > div:last-of-type{
        margin-bottom: 0;
    }

    /* END FILTER */

    /* GAMES LIST */

    .game-list .list{
        justify-content: center !important;
    }

    /* END GAMES LIST */

    /* --- END PAGES --- */
}


@media screen and (max-width: 980px) {
  
    .playlist_container .playlist_video,
    .playlist_container .playlist_list {
      width: 100%;
    }
    .playlist_container .playlist_video {
      width: 100%;
      margin-bottom: 40px;
    }
    .playlist_list .playlist_item .playlist_item_image {
      width: 40% !important;
      max-width: initial;
      height: auto;
      margin: 0 5% 0 0 ;
    }
    .playlist_list .playlist_item .playlist_item_title{
      width: 55% !important;
    }
  
  }

@media (max-width: 768px) {

    /* --- TITLES --- */

    /* SIZE TITLES */

    .title.h1{
        font-size: 30px;
        line-height: 32px;
    }
    .title.h2{
        font-size: 22px;
    }
    .title.h3{
        font-size: 18px;
    }

    /* --- END TITLES --- */

    /* --- HOME --- */

    /* HIGHLIGHT */

    .highlight{
        background-image: initial;
    }

    /* CONTENT */

    .content-news .news{
        margin-bottom: 40px;
    }
    .content-news .news:last-of-type{
        margin-bottom: 0;
    }
    .content-news .news .thumb{
        height: 280px;
    }

    /* END HIGHLIGHT */

    /* TALKS */

    .talks{
        background-image: initial;
    }

    /* CONTENT */

    .content-talks .talk{
        margin-bottom: 40px;
    }
    .content-talks .talk:last-of-type{
        margin-bottom: 0;
    }

    /* END TALKS */

    /* --- END HOME --- */

    /* --- PAGES --- */

    /* FILTER */

    .filter-games .order-by{
        opacity: 0;
        max-height: 0;
        pointer-events: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .filter-games .order-by.active{
        opacity: 1;
        max-height: 500px;
        pointer-events: initial;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    /* END FILTER */

    /* CATEGORIES */

    .filter-games .categories .options{
        max-height: 58px;
        overflow: hidden;
    }
    .filter-games .categories.active .options{
        max-height: 1000px;
    }

    /* END CATEGORIES */

    /* SHOW FILTER AND CATEGORIES */

    .filter-mobile{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #2C2C2C;
        padding-bottom: 20px;
    }

    /* END SHOW FILTER AND CATEGORIES */

    /* GAME DETAIL */

    .game-detail{
        padding: 40px 20px;
        background-image: inherit;
    }

    /* SHARE */

    .game-detail .about-game .content .share {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .game-detail .about-game .content .share .social{
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 15px;
    }
    .game-detail .about-game .content .share .social span {
        margin-right: auto;
    }
    .game-detail .about-game .content .share .like .like-button {
        justify-content: flex-start;
    }
    .game-detail .about-game .content .share .like.active .like-message{
        margin: 0;
    }

    /* END SHARE */

    /* HOW TO PLAY */

    .game-detail .about-game .how-to-play figure{
        width: 100%;
        margin: 0 auto 40px;
        text-align: center;
    }
    .game-detail .about-game .how-to-play figure:last-of-type{
        margin-bottom: 0;
    }

    /* END HOW TO PLAY */

    /* END GAME DETAIL */

    /* --- END PAGES --- */

    /* --- CAROUSEL --- */

    .carousel-slick .slick-list{
        padding-bottom: 0;
        margin-bottom: 60px;
    }
    .carousel-slick .slick-arrow{
        top: 95%;
    }

    /* --- END CAROUSEL --- */

    /* SIGN UP */

    /* --- MODAL --- */

    .custom-modal .modal-dialog .modal-content .img-cadastro {
        margin-bottom: 30px;
    }
    .custom-modal .modal-dialog .modal-content .img-cadastro .web{
        display: none;
    }
    .custom-modal .modal-dialog .modal-content .img-cadastro .mob{
        display: block;
    }
    .custom-modal .modal-dialog .modal-content .content-cadastro {
        padding: 0 10px;
    }
    
    /* END SIGN UP */

    /* --- END MODAL --- */

    /* --- LOGIN --- */
    
    .custom-modal .modal-dialog .modal-content {
        padding: 30px;
    }
    .custom-modal .modal-dialog .modal-content .img-cadastro {
        margin-bottom: 15px !important;
    }
    .custom-modal .modal-dialog .modal-content .content-cadastro .form-group div div > div {
        width: 100% !important;
        height: auto !important;
    }
    .custom-modal .modal-dialog .modal-content .content-cadastro .form-group iframe {
        width: 100%;
    }
    .countdown ul {
        flex-direction: initial;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .countdown ul li {
        margin-right: 0;
        width: 45%;
        display: flex;
        flex-direction: column;
    }
    .content-cadastro form button.btn {
        width: 100%;
    }
    .content-cadastro form a.btn {
        margin: 20px auto 0;
        width: 100%;
    }

    /* --- END LOGIN --- */

}
@media (max-width: 600px) {

    /* --- HOME --- */

    /* HIGHLIGHT */

    /* CONTENT */

    .content-news .news .thumb{
        height: 210px;
    }

    /* END HIGHLIGHT */

    /* --- END HOME --- */

    /* BREADCRUMB */

    .content-bread .breadcrumb {
        justify-content: center!important;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        text-align: center;
    }
    .content-bread .breadcrumb li {
        width: auto;
        padding: 6px 0;
        text-align: center;
        display: inline-block;
        margin: 0 10px 0 0;
    }
    .content-bread .breadcrumb li:before{
        width: 10px;
        height: 10px;
        margin: 7px 10px 0px 0px;
    }

    /* END BREADCRUMB */

    .showMoreText {
        border: 0;
        width: 30px;
        height: 30px;
        background: #ffa200;
        display: block;
        margin: 10px auto;
        color: #fff;
        border-radius: 50%;
    }

    .moreText {
        max-height: 68px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .moreText.show {
        max-height: fit-content;
    }

    .actions-modal .btn {
        margin-left: initial;
        width: 100%;
        margin-bottom: 20px;
    }

    .actions-modal .row {
        width: 100%;
        margin: 0 auto;
    }

    .actions-modal .btn:last-child {
        margin-bottom: 0;
    }
}

/* --- END RESPONSIVE --- */