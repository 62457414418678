@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,700,400italic,700italic&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@charset "UTF-8";

/* CSS Document */

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
ul,
li {
    list-style-type: none;
}
a, button, input[type=submit]{
    transition: all .3s;
}
a,
img,
a:hover, button {
    text-decoration: none;
    outline: none !important;
}
h1, h2, h3, h4{
    font-weight: normal;
}
img{
    max-width: 100%;
    height: auto;
}
:focus, :hover, :active{
    outline: none !important;
}
body{
    overflow-x: hidden;
}

/* --- CONTAINER --- */

.container, .container-md, .container-sm{
    max-width: 1204px;
}

/* --- END CONTAINER --- */

/* FONTS */

@font-face {
    font-family: 'ITC Avant Garde Gothic';
    src: url(/static/media/itc_avant_garde_gothic_bold-webfont.d4e6ccb0.woff2) format('woff2'),
         url(/static/media/itc_avant_garde_gothic_bold-webfont.aa8a5d0d.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Noto Sans Bold';
    src: url(/static/media/notosans-bold-webfont.ac604efc.woff2) format('woff2'),
         url(/static/media/notosans-bold-webfont.f2cb03f6.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Noto Sans';
    src: url(/static/media/notosans-regular-webfont.49613045.woff2) format('woff2'),
         url(/static/media/notosans-regular-webfont.c1535bc0.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Noto Sans Light';
    src: url(/static/media/notosans-light-webfont.a884ee84.woff) format('woff2'),
         url(/static/media/notosans-light-webfont.a884ee84.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

/* --- END FONTS --- */

/* --- COLORS --- */

/* TEXT */

.text-white{
    color: #FFFFFF;
}
.text-black{
    color: #000000;
}
.text-dark-gray{
    color: #0E0E0E;
}
.text-gray{
    color: #C6C6C6;
}
.text-input-gray{
    color: #1A1A1A;
}
.text-magenta{
    color: #FF00AA;
}
.text-purple{
    color: #7800FF;
}
.text-green{
    color: #00FF00;
}
.text-orange{
    color: #FF6A10;
}

/* BACKGROUND */

.bg-white{
    background-color: #FFFFFF;
}
.bg-black{
    background-color: #000000;
}
.bg-dark-gray{
    background-color: #0E0E0E;
}
.bg-gray{
    background-color: #C6C6C6;
}
.bg-input-gray{
    background-color: #1A1A1A;
}
.bg-magenta{
    background-color: #FF00AA;
}
.bg-purple{
    background-color: #7800FF;
}
.bg-green{
    background-color: #00FF00;
}
.bg-orange{
    background-color: #FF6A10;
}

/* BORDER */

.border-white{
    border-color: #FFFFFF;
}
.border-black{
    border-color: #000000;
}
.border-dark-gray{
    border-color: #0E0E0E;
}
.border-gray{
    border-color: #C6C6C6;
}
.border-input-gray{
    border-color: #1A1A1A;
}
.border-magenta{
    border-color: #FF00AA;
}
.border-purple{
    border-color: #7800FF;
}
.border-green{
    border-color: #00FF00;
}
.border-orange{
    border-color: #FF6A10;
}

/* --- END COLORS --- */

/* --- TEXTS --- */

.text{
    font-family: 'Noto Sans Light';
}
.text.bold{
    font-family: 'Noto Sans Bold';
}
.text.itc{
    font-family: 'ITC Avant Garde Gothic';
}

/* SIZE TEXT */

.size-default{
    font-size: 16px;
    line-height: 22px;
}
.size-small{
    font-size: 14px;
}

/* --- END TEXTS --- */

/* --- TITLES --- */

.title{
    font-family: 'ITC Avant Garde Gothic';
}

/* SIZE TITLES */

.title.h1{
    font-size: 50px;
    line-height: 50px;
}
.title.h2{
    font-size: 30px;
}
.title.h3{
    font-size: 20px;
}
.title.h4{
    font-size: 14px;
}

/* --- END TITLES --- */

/* --- BUTTON --- */

/* ACTIONS */

.actions .btn, .actions-modal .btn {
    border-radius: 10px;
    text-transform: uppercase;
    padding: 15px 30px 18px;
    line-height: normal;
    border: 0;
}
.actions .btn:hover, .actions-modal .btn:hover {
    background-color: #7800FF;
}

.actions-modal .btn {
    margin-left: calc(.5 * var(--bs-gutter-x));
}

/* VIEW MORE */

.view-more{
    display: inline-block;
    padding: 0;
    text-transform: uppercase;
}
.view-more:hover{
    color: #7800FF;
}

/* DISCORD */

.btn-discord img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

/* END DISCORD */

/* PLAY */

.btn-play img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

/* END PLAY */

/* --- END BUTTON --- */

/* --- HOME --- */

/* HIGHLIGHT */

.highlight{
    width: 100%;
    height: auto;
    padding: 40px;
    background-image: url(/static/media/pattern-triangles-purple.37e4f2c9.svg);
    background-size: 650px;
    background-position: calc(100% - 5px) 10px;
    background-repeat: no-repeat;
}

/* CONTENT */

.content-news .news .thumb{
    width: 100%;
    height: 210px;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 0;
}
.content-news .news .thumb img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* END HIGHLIGHT */

/* GAMES */

.games{
    width: 100%;
    height: auto;
    padding: 0 20px 40px;
}

/* CONTENT */

.games.fullscreen .content-games .game{
    margin-bottom: 40px;    
}
.games.fullscreen .content-games .game .content .title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 46px;
}
.games.fullscreen .content-games .game .content p.text{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 66px;
}
.games.fullscreen .content-games .game .thumb{
    width: 100%;
    height: 210px;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 0;
}
.games.fullscreen .content-games .game .thumb img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* END GAMES */

/* TALKS */

.talks{
    width: 100%;
    height: auto;
    padding: 40px;
    background-color: #0E0E0E;
    background-image: url(/static/media/pattern-lines-pink.ea9b615c.svg);
    background-size: 100px;
    background-position: 0 bottom;
    background-repeat: no-repeat;
}

/* CONTENT */

.talks .content-talks .talk {
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
}
.talks .content-talks .talk .content .title{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 74px;
}
.talks .content-talks .talk .thumb{
    width: 100%;
    height: 210px;
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 0;
}
.talks .content-talks .talk .thumb img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* END TALKS */

/* PLAYLIST */

/*.playlist_container {
    max-height: 600px;
}

.playlist_list {
    overflow-y: scroll;
    max-height: 600px;
}

.playlist_item_image {
    border-radius: 30px;
}*/


.playlist_list {
    overflow-y: auto;
}

  .playlist_container, .playlist_list {
    max-height: 440px;
  }

  .playlist_list::-webkit-scrollbar {
    width: 5px;
  }

  .playlist_list::-webkit-scrollbar-track {
    background-color: #0E0E0E;
    border-radius: 10px;
  }

  .playlist_list::-webkit-scrollbar-thumb {
    background-color: #FF6A10;
    border-radius: 10px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  .playlist_list::-webkit-scrollbar-thumb:hover {
    background-color: #7800FF;
    border-radius: 10px;
  }

  .playlist_list .playlist_item {
    cursor: pointer;
    opacity: 0.5;
    margin: 0 0 20px !important;
    transition: all 0.3s;
  }

  .playlist_list .playlist_item:hover{
    opacity: 1;
  }

  .playlist_list .playlist_item.active{
    opacity: 1;
  }

  .playlist_list .playlist_item .playlist_item_image {
    width: 100%;
    max-width: 140px;
    height: 90px;
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
    padding: 0;
    margin: 0;
    flex: none;
  }

  .playlist_list .playlist_item .playlist_item_title{
    font-family: "ITC Avant Garde Gothic";
    font-size: 14px;
    line-height: 18px;
  }
  
  .playlist_video .playlist_video_container{
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
  }

  .playlist_video .playlist_video_container iframe{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }




/* END PLAYLIST */

/* --- END HOME --- */

/* --- PAGES --- */

/* HEADER */

.header-page{
    width: 100%;
    height: auto;
    padding: 40px;
    background-image: url(/static/media/pattern-circle-purple.5c7a5502.svg);
    background-size: 650px;
    background-position: calc(100% - 5px) 100%;
    background-repeat: no-repeat;
    border-bottom: 1px solid #2C2C2C;
}
.header-page .title{
    font-size: 0.1px !important;
    margin: 0;
    padding: 0;
}
.header-page figure {
    max-width: 400px;
}
.header-page .content-subtitle{
    max-width: 660px;
}

/* END HEADER */

/* BREADCRUMB */

.content-bread {
    border-bottom: 1px solid #2C2C2C;
}
.content-bread .breadcrumb {
    padding: 0;
    background-color: transparent;
    margin: 20px 0;
}
.content-bread .breadcrumb a:hover{
    color: #FF6A10;
}
.content-bread .breadcrumb li:first-of-type::before{
    margin: 0;
    display: none;
}
.content-bread .breadcrumb li::before{
    content: '';
    background-image: url(/static/media/icon-right.c2a9eb27.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    display: block;
    margin: 5px 15px 0px 10px;
}

/* END BREADCRUMB */

/* FILTER */

.filter-games{
    width: 100%;
    height: auto;
    padding: 40px;
}
.filter-games .order-by{
    width: 100%;
    border-bottom: 1px solid #2C2C2C;
    padding-bottom: 20px;
    margin-bottom: 20px;
    transition: all .3s;
}

/* END FILTER */

/* SHOW FILTER AND CATEGORIES */

.filter-mobile{
    display: none;
    width: 100%;
    margin-bottom: 20px;
}
.filter-mobile .showFilterMobile,
.filter-mobile .showCategoriesMobile{
    background-color: #FF6A10;
    background-image: url(/static/media/icon-plus.f3cd7b9c.svg);
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: center;
    border: 0;
    width: 32px;
    height: 32px;
    cursor: pointer;
    font-size: 0.1px;
    border-radius: 50%;
}
.filter-mobile .showFilterMobile.active,
.filter-mobile .showCategoriesMobile.active{
    background-color: #FF00AA;
    background-image: url(/static/media/icon-minus.304c3d41.svg);
}

/* END SHOW FILTER AND CATEGORIES */

/* CATEGORIES */

.categories .options{
    width: 100%;
    transition: all .3s;
}
.categories .options.active{
    border-bottom: 1px solid #2C2C2C;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.categories .options .btn-category {
    display: inline-block;
    border: 1px solid #C6C6C6;
    border-radius: 10px;
    background-color: transparent;
    padding: 7px 19px 10px;
    margin: 0 10px 10px 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #C6C6C6;
}
.categories .options .btn-category:hover{
    border: 1px solid #FF6A10;
    color: #FF6A10;
}
.categories .options .btn-category.active{
    background-color: #FF6A10;
    border: 1px solid #FF6A10;
    color: #FFFFFF;
}
.categories .result-filter .result-button{
    background-color: transparent;
    background-image: url(/static/media/icon-delete.4d981659.svg);
    background-size: 16px;
    background-position: calc(100% - 0px) 2px;
    background-repeat: no-repeat;
    border: 1px solid transparent;
    padding: 0 25px 0 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #C6C6C6;
}
.categories .result-filter .result-button:hover{
    color: #FF00AA;
    border-bottom: 1px solid #FF00AA;
}

/* END CATEGORIES */

/* GAMES LIST */

.game-list .list{
    margin: 0 -10px 0;
}
.game-list .list .card{
    width: 100%;
    max-width: 130px;
    background-color: transparent;
    border: 0;
    margin: 0 10px 20px 10px;
}
.game-list .list .card a{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.game-list .list .card .visual{
    position: relative;
}

/* AGE  */

.age{
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    z-index: 2;
}
.age.color::before{
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
}
.age.color.orange::before{
    background-color: rgba(255,106,16,.8);
}
.age.color.blue::before{
    background-color: rgba(0,15,255,.8);
}
.age.color.yellow::before{
    background-color: rgba(247,166,0,.8);
}
.age.color.green::before{
    background-color: rgba(0,150,64,.8);
}
.age.color.black::before{
    background-color: rgba(0,0,0,.8);
}
.age.color p{
    text-align: center;
    position: relative;
    z-index: 1;
    line-height: normal;
    padding: 0 2px 3px;
}

/* END AGE */

.game-list .list .card .visual figure{
    width: 130px;
    height: 130px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;
    z-index: 0;
}
.game-list .list .card .visual figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .3s;
}
.game-list .list .card a:hover .visual figure img{
    transform: scale(1.2) rotate(10deg);
}
.game-list .list .card.award .visual:before {
    content: "";
    background-image: url(/static/media/icon-medal.cc689554.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 32px;
    height: 32px;
    position: absolute;
    z-index: 1;
    left: calc(50% - 16px);
    bottom: 0;
}
.game-list .list .card .info .title{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 44px;
}
.game-list .list .card .info .title,
.game-list .list .card .info p{
    transition: all .3s;
}
.game-list .list .card a:hover .info .title,
.game-list .list .card a:hover .info p{
    color: #FF6A10 !important;
}

/* ADVERTISING */

.advertising{
    width: 100%;
    margin: 20px 0 40px;
}
.advertising:last-of-type{
    margin-bottom: 0;
}
.advertising figure{
    width: 100%;
    padding: 0;
    margin: 0;
    border-radius: 15px;
    overflow: hidden;
}
.advertising figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* END ADVERTISING */

/* END GAMES LIST */

/* GAME DETAIL */

.game-detail{
    width: 100%;
    height: auto;
    padding: 40px;
    background-image: url(/static/media/pattern-triangles-purple-2.02369159.svg);
    background-size: 300px;
    background-position: calc(100% - 5px) 0px;
    background-repeat: no-repeat;
}
.game-detail .about-game > div{
    padding-bottom: 30px;
    margin-bottom: 40px;
    border-bottom: 1px solid #2C2C2C;
}
.game-detail .about-game > div:last-of-type{
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

/* VIDEO */

.game-detail .about-game .video {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    border-radius: 30px;
    margin-bottom: 20px;
}
.game-detail .about-game .video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

/* END VIDEO */

/* ABOUT */

.game-detail .about-game .content .pegi > div{
    margin-right: 15px;
}
.game-detail .about-game .content .pegi .age{
    position: relative;
    top: inherit;
    left: inherit;
    width: 32px;
    height: 32px;
}
.game-detail .about-game .content .pegi .award{
    width: 32px;
    height: 32px;
}
.game-detail .about-game .content .award img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.game-detail .about-game .content .about{
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #2C2C2C;
}
.game-detail .about-game .content .about p:last-of-type{
    margin-bottom: 0;
    padding-bottom: 0;
}

/* END ABOUT */

/* SHARE */

.game-detail .about-game .content .share{
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #2C2C2C;
}
.game-detail .about-game .content .share .social span{
    margin-right: 10px;
}
.game-detail .about-game .content .share .social a{
    margin: 0 5px;
}
.game-detail .about-game .content .share .social a:hover{
    transform: scale(1.15);
}
.game-detail .about-game .content .share .like .like-button{
    width: 100%;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
} 
.game-detail .about-game .content .share .like .like-button .title{
    margin-right: 15px;
}
.game-detail .about-game .content .share .like .like-button .icon-like{
    width: 24px;
    height: 24px;
    background-image: url(/static/media/icon-heart-border.711fad3f.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    margin-right: 5px;
    transition: all .3s;
}
.game-detail .about-game .content .share .like .like-button:hover .icon-like,
.game-detail .about-game .content .share .like.active .like-button .icon-like{
    background-image: url(/static/media/icon-heart-full.6360b6a7.svg);
}
.game-detail .about-game .content .share .like .like-button .number-like{
    color: #FF00AA;
    font-size: 14px;
}
.game-detail .about-game .content .share .like .like-message{
    height: 0;
    opacity: 0;
    margin: -3px 0 0 10px;
    pointer-events: none;
    transition: all .3s;
}
.game-detail .about-game .content .share .like.active .like-message{
    height: 21px;
    opacity: 1;
    pointer-events: none;
}

/* END SHARE */

/* HOW TO PLAY */

.game-detail .about-game .how-to-play figure{
    max-height: 340px;
    margin: 0 20px 20px 0;
}
.game-detail .about-game .how-to-play figure img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* END HOW TO PLAY */

.socialImage {
    height: 30px;
    color: #FFF;
    fill: #FFF;
    margin: 10px 5px;
}

.showMoreText {
    display: none;
}

/* END GAME DETAIL */

/* --- END PAGES --- */

/* --- FORM --- */

.form-select:focus, :focus, .form-control:focus{
    background-color: #1A1A1A;
    color: #C6C6C6;
    box-shadow: none !important;
    border: 0;
    outline: 0;
}
.form-control::placeholder{
    color: #C6C6C6;
    font-family: 'Noto Sans';
    font-size: 14px;
}
input[type=text], input[type=password], input[type=email], input[type=tel], input[type=number],
.form-select, .form-control, select, textarea {
    width: 100%;
    height: 48px;
    padding: 10px 15px 14px;
    color: #C6C6C6;
    font-family: 'Noto Sans';
    font-size: 14px;
    line-height: normal;
    background-color: #1A1A1A;
    border-radius: 10px;
    border: 0;
    -webkit-appearance: none;
            appearance: none;
    -moz-appearance: none;
}
.form-select, select{
    background-color: #1A1A1A;
    color: #C6C6C6;
    background-image: url(/static/media/icon-down.1e1e34ba.svg);
    --bs-form-select-bg-img: url(/static/media/icon-down.1e1e34ba.svg);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) center;
}
.react-select__control {
    width: 100%;
    height: 48px;
    border-radius: 10px !important;
    border: 0 !important;
    color: #c6c6c6;
    overflow: hidden;
    background-color: #1a1a1a !important;
  }

  .react-select__control .react-select__single-value {
    color: #c6c6c6;
  }

  .react-select__control .react-select_value-container{
    height: 100%;
    padding: 0 13px;
    flex: 1 1;
    display: flex;
  }
  .react-select__control .react-selectvalue-container .react-select_placeholder,
  .react-select__control .react-selectvalue-container .react-select_input-container{
    color: #c6c6c6 !important; 
  }
  .react-select__control .react-selectindicators .react-select_indicator-separator{
    background-color: #363636;
  }
  .react-select-container .react-select__menu{
    background-color: #1a1a1a !important;
    color: #c6c6c6 !important;
  }


textarea{
    height: 120px;
    resize: none;
}
.form-check-input[type=checkbox], input[type=checkbox]{
    width: 18px;
    height: 18px;
    background-color: #1A1A1A;
    border: 0;
    margin-right: 10px;
    margin-top: 3px;
}
.form-group a{
    color: #FF6A10;
}
.form-group a:hover{
    color: #7800FF;
}

/* --- END FORM --- */

/* --- CAROUSEL --- */

.carousel-slick .slick-list{
    padding-bottom: 35px;
    margin-bottom: 80px;
}
.carousel-slick .slick-arrow::before{
    content: '' !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 42px;
    height: 42px;
    display: block;
    transition: all .3s;
    opacity: 1;
}
.carousel-slick .slick-arrow{
    width: 42px;
    height: 42px;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    transform: translate(0);
}
.carousel-slick .slick-arrow.slick-prev{
    left: calc(50% - 47px);
}
.carousel-slick .slick-arrow.slick-next{
    left: calc(50% - -5px);
}
.carousel-slick .slick-arrow:hover::before{
    transform: scale(1.1);
    top: 43.5%;
}
.carousel-slick .slick-prev::before{
    background-image: url(/static/media/arrows-left.c560faa5.svg);
}
.carousel-slick .slick-next::before{
    background-image: url(/static/media/arrows-right.5bd68391.svg);
}

/* --- END CAROUSEL --- */

/* --- MODAL --- */

.modal-backdrop {
    opacity: .8 !important;
    background: #7800FF !important;
    --bs-backdrop-bg: #7800FF;
}
.modal-backdrop:nth-last-of-type(2){
    display: none !important;
}
.custom-modal{
    background: rgb(26 26 26 / 80%) !important;
}
.custom-modal .modal-dialog{
    width: 90%;
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
}
.custom-modal .modal-dialog .modal-content .btn-close{
    width: 24px;
    height: 24px;
    right: 12px;
    top: 12px;
    position: absolute;
    opacity: 1;
}
.custom-modal .modal-dialog .modal-content .btn-close button{
    background-color: transparent;
    background-image: url(/static/media/icon-delete-white.b99aadab.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    cursor: pointer;
    opacity: 1;
    border: 0;
    text-indent: -99999px;
    transition: all .3s;
}
.custom-modal .modal-dialog .modal-content .btn-close:hover button{
    transform: scale(1.15);
}
.custom-modal .modal-dialog .modal-content{
    background:#000;
    padding: 40px;
    border-radius: 30px;
    border: 0;
}

/* ESTANDE */

.custom-modal .modal-dialog .modal-content .content.estande .image{
    position: relative;
    overflow: hidden;
}
.custom-modal .modal-dialog .modal-content .content.estande .image .points {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50px;
    top: 50px;
    z-index: 1;
}
.custom-modal .modal-dialog .modal-content .content.estande .image .points .point {
    width: 32px;
    height: 32px;
    position: absolute;
    left: 15%;
    top: 39%;
}
.custom-modal .modal-dialog .modal-content .content.estande .image .points .point a {
    background-color: #FFFFFF;
    background-image: url(/static/media/icon-plus-black.9600d2e9.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 20px;
    width: 32px;
    height: 32px;
    display: block;
    border-radius: 50%;
    position: relative;
    z-index: 1;
}
.custom-modal .modal-dialog .modal-content .content.estande .image .points .point a:hover{
    background-color: #FF6A10;
    background-image: url(/static/media/icon-plus.f3cd7b9c.svg);
}
.custom-modal .modal-dialog .modal-content .content.estande .image .points .point .efeito{
    position: absolute;
    top: -48px;
    left: -48px;
    border-style: solid;
    border-width: 0px;
    border-color: rgb(255, 255, 255);
    background-color: rgb(255, 255, 255);
    height: 400%;
    width: 400%;
    margin: -0px;
    border-radius: 50%;
    -webkit-animation: pulsar_pulsate 4s ease-out;
    -webkit-animation-iteration-count: infinite;
    -ms-animation: pulsar_pulsate 4s ease-out;
    -ms-animation-iteration-count: infinite;
    animation: pulsar_pulsate 4s ease-out;
    animation-iteration-count: infinite;
    opacity: 0.0;
    z-index: 0;
}
.custom-modal .modal-dialog .modal-content .content.estande .image figure{
    width: 100%;
    max-height: 534px;
    border-radius: 20px;
    overflow: hidden;
    margin: 0;
}
.custom-modal .modal-dialog .modal-content .content.estande .image figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* SIGN UP */

.custom-modal .modal-dialog .modal-content .img-cadastro{
    overflow: hidden;
}
.custom-modal .modal-dialog .modal-content .img-cadastro img{
    width: 100%;
    height: 100%;
    border-radius: 20px;
    object-fit: cover;
}
.custom-modal .modal-dialog .modal-content .img-cadastro .web{
    display: block;
}
.custom-modal .modal-dialog .modal-content .img-cadastro .mob{
    display: none;
}
.custom-modal .modal-dialog .modal-content .content-cadastro {
    /*padding: 0 40px;*/
}
.custom-modal .modal-dialog .modal-content .content-cadastro .form-group{
    margin-bottom: 15px;   
}
.custom-modal .modal-dialog .modal-content .content-cadastro .form-group:last-of-type{
    margin-left: 0;
}

/* END SIGN UP */

/* --- END MODAL --- */

/* --- ANIMATION --- */

/* PIN ESTANDE */
@keyframes pulsar_pulsate {
    0% {
        -webkit-transform: scale(0, 0);
        opacity: 0.0;
    }
    50% {
        opacity: 1.0;
    }
    100% {
        -webkit-transform: scale(0.5, 0.5);
        opacity: 0.0;
    }
}

/* --- END ANIMATION --- */


/* --- LOGIN --- */

.countdown ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
}
.countdown ul li{
    font-family: "ITC Avant Garde Gothic";
    font-weight: 700;
    font-size: 24px;
    text-transform: uppercase;
    margin-right: 15px;
}
.countdown ul li span{
    color: #ff6910;
}
.content-cadastro form button.btn {
    font-family: "ITC Avant Garde Gothic";
    text-transform: uppercase;
    font-weight: 700;
    padding: 15px 40px;
    background-color: #FF6A10;
    color: #FFFFFF;
    border: 0;
    font-size: 16px;
    margin-right: 20px;
}
.content-cadastro form button.btn:hover{
    background-color: #7800FF;
}
.content-cadastro form a.btn {
    font-family: "ITC Avant Garde Gothic";
    color: #fff;
    font-size: 14px !important;
    padding: 0;
}
.content-cadastro form a.btn:hover{
    background-color: transparent;
    color: #7800FF;
}

/* --- END LOGIN --- */


.text-view-more {
    display: -webkit-box;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-view-more.active {
    display: block;
}

.btn-view-more {
    margin-top: 10px;
    display: contents;
    text-decoration: none;
    padding: 0;
    border: none;
}
.btn-view-more:hover {
    color: #7800ff !important;
}



/* --- ADS --- */

.ads {
    width: 100%;
    margin: 15px 0 20px;
    background-color: #000;
}
.ads .container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.ads .cols{
    margin: 0 15px;
}
.ads .cols .title-brand {
    height: 20px;
}
.ads .cols .brands {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
}
.ads .cols .brands figure {
    margin: 0 25px 0 0;
    width: 120px;
    height: 70px;
}
.ads .cols .brands figure:last-child{
    margin-right: 0;
}
.ads .cols .brands figure img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (max-width: 982px) {
    .ads .cols{
        width: 100%;
        margin: 10px 0;
    }
    .ads .cols .brands {
        justify-content: center;
    }
    .ads .cols .brands figure {
        margin: 0 20px 0 0;
        width: 90px;
        height: 55px;
    }
    .ads .cols .title-brand {
        text-align: center;
    }
}

/* --- END ADS --- */

/* --- RESPONSIVE --- */

@media (max-width: 1600px) {
    /* --- PAGES --- */

    /* GAME DETAIL */

    .game-detail{
        background-size: 250px;
        background-position: calc(105% - 5px) 0px;
    }

    /* END GAME DETAIL */

    /* --- END PAGES --- */
}
@media (max-width: 1366px) {

    /* --- PAGES --- */

    /* GAME DETAIL */

    .game-detail{
        background-position: calc(112% - 5px) 0px;
    }

    /* END GAME DETAIL */

    /* --- END PAGES --- */

}

@media screen and (max-width: 1280px) {

    .playlist_container {
      max-width: 95%;
      margin: auto;
    }
  
  }
  

@media (max-width: 1200px) {

    /* --- PAGES --- */

    /* HEADER */

    .header-page{
        background-position: calc(160% - 5px) 100%;
    }

    /* END HEADER */

    /* --- END PAGES --- */

}
@media (max-width: 982px) {

    /* --- CONTAINER --- */

    .container, .container-md, .container-sm{
        max-width: 100%;
    }

    /* --- END CONTAINER --- */

    /* --- HOME --- */

    /* HIGHLIGHT */

    .highlight{
        padding: 20px;
        background-image: url(/static/media/pattern-triangles-pink-2.acedb3e1.svg);
        background-size: 200px;
    }

    /* END HIGHLIGHT */

    /* GAMES */

    .games{
        padding: 20px;
    }
    
    /* END GAMES */

    /* TALKS */

    .talks{
        padding: 40px 20px;
    }

    /* END TALKS */

    /* --- END HOME --- */

    /* --- PAGES --- */

    /* HEADER */

    .header-page{
        background-image: inherit;
        padding: 40px 20px;
    }
    .header-page .content-subtitle{
        max-width: 100%;
    }

    /* END HEADER */

    /* FILTER */

    .filter-games{
        padding: 40px 20px;
    }
    .filter-games .order-by .row > div{
        margin-bottom: 20px;
    }
    .filter-games .order-by .row > div:last-of-type{
        margin-bottom: 0;
    }

    /* END FILTER */

    /* GAMES LIST */

    .game-list .list{
        justify-content: center !important;
    }

    /* END GAMES LIST */

    /* --- END PAGES --- */
}


@media screen and (max-width: 980px) {
  
    .playlist_container .playlist_video,
    .playlist_container .playlist_list {
      width: 100%;
    }
    .playlist_container .playlist_video {
      width: 100%;
      margin-bottom: 40px;
    }
    .playlist_list .playlist_item .playlist_item_image {
      width: 40% !important;
      max-width: initial;
      height: auto;
      margin: 0 5% 0 0 ;
    }
    .playlist_list .playlist_item .playlist_item_title{
      width: 55% !important;
    }
  
  }

@media (max-width: 768px) {

    /* --- TITLES --- */

    /* SIZE TITLES */

    .title.h1{
        font-size: 30px;
        line-height: 32px;
    }
    .title.h2{
        font-size: 22px;
    }
    .title.h3{
        font-size: 18px;
    }

    /* --- END TITLES --- */

    /* --- HOME --- */

    /* HIGHLIGHT */

    .highlight{
        background-image: initial;
    }

    /* CONTENT */

    .content-news .news{
        margin-bottom: 40px;
    }
    .content-news .news:last-of-type{
        margin-bottom: 0;
    }
    .content-news .news .thumb{
        height: 280px;
    }

    /* END HIGHLIGHT */

    /* TALKS */

    .talks{
        background-image: initial;
    }

    /* CONTENT */

    .content-talks .talk{
        margin-bottom: 40px;
    }
    .content-talks .talk:last-of-type{
        margin-bottom: 0;
    }

    /* END TALKS */

    /* --- END HOME --- */

    /* --- PAGES --- */

    /* FILTER */

    .filter-games .order-by{
        opacity: 0;
        max-height: 0;
        pointer-events: none;
        margin-bottom: 0;
        padding-bottom: 0;
    }
    .filter-games .order-by.active{
        opacity: 1;
        max-height: 500px;
        pointer-events: initial;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    /* END FILTER */

    /* CATEGORIES */

    .filter-games .categories .options{
        max-height: 58px;
        overflow: hidden;
    }
    .filter-games .categories.active .options{
        max-height: 1000px;
    }

    /* END CATEGORIES */

    /* SHOW FILTER AND CATEGORIES */

    .filter-mobile{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #2C2C2C;
        padding-bottom: 20px;
    }

    /* END SHOW FILTER AND CATEGORIES */

    /* GAME DETAIL */

    .game-detail{
        padding: 40px 20px;
        background-image: inherit;
    }

    /* SHARE */

    .game-detail .about-game .content .share {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .game-detail .about-game .content .share .social{
        width: 100%;
        justify-content: flex-end;
        margin-bottom: 15px;
    }
    .game-detail .about-game .content .share .social span {
        margin-right: auto;
    }
    .game-detail .about-game .content .share .like .like-button {
        justify-content: flex-start;
    }
    .game-detail .about-game .content .share .like.active .like-message{
        margin: 0;
    }

    /* END SHARE */

    /* HOW TO PLAY */

    .game-detail .about-game .how-to-play figure{
        width: 100%;
        margin: 0 auto 40px;
        text-align: center;
    }
    .game-detail .about-game .how-to-play figure:last-of-type{
        margin-bottom: 0;
    }

    /* END HOW TO PLAY */

    /* END GAME DETAIL */

    /* --- END PAGES --- */

    /* --- CAROUSEL --- */

    .carousel-slick .slick-list{
        padding-bottom: 0;
        margin-bottom: 60px;
    }
    .carousel-slick .slick-arrow{
        top: 95%;
    }

    /* --- END CAROUSEL --- */

    /* SIGN UP */

    /* --- MODAL --- */

    .custom-modal .modal-dialog .modal-content .img-cadastro {
        margin-bottom: 30px;
    }
    .custom-modal .modal-dialog .modal-content .img-cadastro .web{
        display: none;
    }
    .custom-modal .modal-dialog .modal-content .img-cadastro .mob{
        display: block;
    }
    .custom-modal .modal-dialog .modal-content .content-cadastro {
        padding: 0 10px;
    }
    
    /* END SIGN UP */

    /* --- END MODAL --- */

    /* --- LOGIN --- */
    
    .custom-modal .modal-dialog .modal-content {
        padding: 30px;
    }
    .custom-modal .modal-dialog .modal-content .img-cadastro {
        margin-bottom: 15px !important;
    }
    .custom-modal .modal-dialog .modal-content .content-cadastro .form-group div div > div {
        width: 100% !important;
        height: auto !important;
    }
    .custom-modal .modal-dialog .modal-content .content-cadastro .form-group iframe {
        width: 100%;
    }
    .countdown ul {
        flex-direction: initial;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .countdown ul li {
        margin-right: 0;
        width: 45%;
        display: flex;
        flex-direction: column;
    }
    .content-cadastro form button.btn {
        width: 100%;
    }
    .content-cadastro form a.btn {
        margin: 20px auto 0;
        width: 100%;
    }

    /* --- END LOGIN --- */

}
@media (max-width: 600px) {

    /* --- HOME --- */

    /* HIGHLIGHT */

    /* CONTENT */

    .content-news .news .thumb{
        height: 210px;
    }

    /* END HIGHLIGHT */

    /* --- END HOME --- */

    /* BREADCRUMB */

    .content-bread .breadcrumb {
        justify-content: center!important;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        flex-direction: row;
        text-align: center;
    }
    .content-bread .breadcrumb li {
        width: auto;
        padding: 6px 0;
        text-align: center;
        display: inline-block;
        margin: 0 10px 0 0;
    }
    .content-bread .breadcrumb li:before{
        width: 10px;
        height: 10px;
        margin: 7px 10px 0px 0px;
    }

    /* END BREADCRUMB */

    .showMoreText {
        border: 0;
        width: 30px;
        height: 30px;
        background: #ffa200;
        display: block;
        margin: 10px auto;
        color: #fff;
        border-radius: 50%;
    }

    .moreText {
        max-height: 68px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .moreText.show {
        max-height: -webkit-fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
    }

    .actions-modal .btn {
        margin-left: initial;
        width: 100%;
        margin-bottom: 20px;
    }

    .actions-modal .row {
        width: 100%;
        margin: 0 auto;
    }

    .actions-modal .btn:last-child {
        margin-bottom: 0;
    }
}

/* --- END RESPONSIVE --- */
 /*@import url(https://fonts.googleapis.com/css?family=Raleway:800,400);
 @import url(https://fonts.googleapis.com/css?family=Cabin);
 @import url(https://fonts.googleapis.com/css?family=Lato);
 */

 .birdseye-header {
    font-family: 'Open Sans', sans-serif;
 }
 .top-bar{
   height: 37px;
 }
 .top-menu, .events{
    align-items: center;
 }
 .nav{
   min-height: 94px;
   align-items: center;
   justify-content: flex-end;
 }
 .nav ul,
 .top-menu ul{
    margin-bottom: 0;
 }
 .birdseye-header .container {
   max-width: 100%;
   padding: 0;
   margin: 0;
}
.birdseye-header .logo img{
   max-height: 55px;
}
table.wsite-multicol-table {
   width: 100%;
}
table.wsite-multicol-table tr.wsite-multicol-tr td {
   vertical-align: top;
}
.language a:before {
   display: inline-block;
   content: ''
}
.language a {
   color: white!important;
   font-size: 14px;
}
.language a span {
   margin-left: 13px;
   display: inline-block;
   position: relative;
   top: -7px;
}
.wsite-image.wsite-image-border-thick {
   padding-left: 0 !important;
   margin-left: 0 !important;
}
.footer-wrap .container {
    padding: 30px 15px;
}

 /*ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, body, html, div.paragraph, blockquote, fieldset, input { margin: 0; padding: 0; }
 ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, body, html, p, blockquote, fieldset, input { margin: 0; padding: 0; }
 html { height: 100%; }
 body { width: 100%; height: 100%; background: #fff; color: #190f3b; font-family: 'Open Sans', sans-serif; font-size: 18px; font-weight: normal; line-height: 1.5em; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale; }
 a { color: #e9bb00; font-weight: bold; text-decoration: none; }
 a:hover { color: #0c0429; }
 a img, a:hover img { border: none; }
 h2 { color: #0c0429; font-family: 'Open Sans', serif; font-size: 50px; font-weight: 600; padding: 20px 0; line-height: normal; }
 div.paragraph { font-family: 'Open Sans', sans-serif; font-size: 20px; font-weight: normal; line-height: 22px; }
 p { font-family: 'Open Sans', sans-serif; font-size: 20px; font-weight: normal; line-height: 22px; }
 blockquote { font-family: 'Open Sans', serif; font-size: 24px; color: #10072b; font-weight: 400; line-height: normal; font-style: inherit; text-align: left; line-height: 32px; border-left:0; margin: 1em 0; }
  input[type="text"], input[type="email"], textarea { -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; -webkit-appearance: none; -moz-appearance: none; appearance: none; outline: none; text-shadow: none; }
 textarea { resize: none; }
 select { -webkit-appearance: none; -moz-appearance: none; appearance: none; outline: none; text-indent: 0.01px; text-overflow: ''; }
 input[type="radio"], input[type="checkbox"] { width: 16px; height: 16px; border: 1px solid #cccccc !important; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; -webkit-appearance: none; -moz-appearance: none; appearance: none; outline: none; text-shadow: none; }
 input[type="radio"] { border-radius: 8px; }
 input[type="checkbox"] { background-color: white; border-radius: 0; }
 input[type="radio"]:after { display: block; border-radius: 8px; box-sizing: border-box; content: ""; }
 input[type="radio"]:checked:after { background: #cccccc; border: 2px solid #fafafa; width: 14px; height: 14px; }
 input[type="checkbox"]:after { position: relative; top: 1px; left: 4px; width: 3px; height: 6px; border: solid #cccccc; border-width: 0 2px 2px 0; -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -ms-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg); }
 input[type="checkbox"]:checked:after { display: block; content: " "; }
 .container { width: 960px; margin: 0 auto; }*/
 #icontent .wrapper .birdseye-header .logo, #preview-iframe .landing-page .wrapper .birdseye-header .logo, #icontent.landing-page .wrapper .birdseye-header .logo, #icontent .wrapper .nav, #preview-iframe .landing-page .wrapper .nav, #icontent.landing-page .wrapper .nav, #icontent .wrapper .banner-wrap .container, #preview-iframe .landing-page .wrapper .banner-wrap .container, #icontent.landing-page .wrapper .banner-wrap .container, #icontent.landing-page .fader { opacity: 1 !important; }
 .birdseye-header .nav-wrap .container{ margin-top: 25px; }
 body.fade-on-scroll .banner { opacity: 0; }
 body.page-has-banner .birdseye-header { padding: 20px 30px; }
 body.page-has-banner .logo img { max-height: 60px; }
 body.page-has-banner .main-wrap { padding-top: 70px; }
 /*.paragraph a:hover { color: #160e33; }
 .parallax { width: 100%; min-height: 400px; box-sizing: border-box; -webkit-transition: all 500ms ease; -moz-transition: all 500ms ease; -ms-transition: all 500ms ease; -o-transition: all 500ms ease; transition: all 500ms ease; }
 .parallax h2 { padding-bottom: 5px; color: #ffffff !important; }
 .parallax div.paragraph { font-size: 16px; line-height: 1.5; padding: 10px 0 5px; color: #ffffff !important; }
 .parallax p { font-size: 16px; line-height: 1.5; padding: 10px 0 5px; color: #ffffff !important; }
 .parallax blockquote { font-size: 16px; line-height: 28px; color: #ffffff !important; padding: 1em; margin: 2em 0; border-left: 0px !important; }
 @media screen and (max-width: 1024px) {
 .parallax { background-attachment: scroll !important; }
 .birdseye-header .nav-wrap .container{ margin-top:0!important; position:relative; }
 }*/

 .language a:before{ display:inline-block; content: url(https://www.bigfestival.com.br/files/theme/global.png); }

 /*.language a{ color: white!important; font-size:14px; }
 .language a:hover{ color: #e9bb00; }
 .language a span { margin-left: 13px; display: inline-block; position: relative; top: -7px; }*/
 /*.countdown { color: #e9bb00; margin: 0 auto; padding: 0.5rem; text-align: center; font-weight: 900; line-height: 4em; }
 .countdown li { display: inline-block; font-size: 1.5em; list-style-type: none; padding: 1em; text-transform: uppercase; }
 .countdown li span { display: block; font-size: 7.5rem; }
 @media screen and (max-width: 992px) {
 .countdown { line-height: 2em; }
 .countdown li { font-size: 0.7em; padding: 0.5em; }
 .countdown li span { font-size: 3rem; }
 }*/
 /*
 #cd-vertical-nav { position: fixed; right: 40px; top: 50%; bottom: auto; -webkit-transform: translateY(-50%); -moz-transform: translateY(-50%); -ms-transform: translateY(-50%); -o-transform: translateY(-50%); transform: translateY(-50%); z-index: 1; }
 #cd-vertical-nav li { text-align: right; list-style:none; }
 #cd-vertical-nav a { display: inline-block; -webkit-backface-visibility: hidden; backface-visibility: hidden; }
 #cd-vertical-nav a:after { content: ""; display: table; clear: both; }
 #cd-vertical-nav a span { display: inline-block; float: right; -webkit-transform: scale(0.6); -moz-transform: scale(0.6); -ms-transform: scale(0.6); -o-transform: scale(0.6); transform: scale(0.6); }
 #cd-vertical-nav a:hover span { -webkit-transform: scale(1); -moz-transform: scale(1); -ms-transform: scale(1); -o-transform: scale(1); transform: scale(1); }
 #cd-vertical-nav a:hover .cd-label { opacity: 1; }
 #cd-vertical-nav a.is-selected .cd-dot { background-color: black; }
 #cd-vertical-nav .cd-dot { position: relative; top: 8px; height: 12px; width: 12px; border-radius: 50%; background-color: #d88683; -webkit-transition: -webkit-transform 0.2s, background-color 0.5s; -moz-transition: -moz-transform 0.2s, background-color 0.5s; transition: transform 0.2s, background-color 0.5s; -webkit-transform-origin: 50% 50%; -moz-transform-origin: 50% 50%; -ms-transform-origin: 50% 50%; -o-transform-origin: 50% 50%; transform-origin: 50% 50%; }
 #cd-vertical-nav .cd-label { position: relative; margin-right: 10px; padding: .4em .5em; color: black; font-size: 14px; font-size: 0.875rem; -webkit-transition: -webkit-transform 0.2s, opacity 0.2s; -moz-transition: -moz-transform 0.2s, opacity 0.2s; transition: transform 0.2s, opacity 0.2s; opacity: 0; -webkit-transform-origin: 100% 50%; -moz-transform-origin: 100% 50%; -ms-transform-origin: 100% 50%; -o-transform-origin: 100% 50%; transform-origin: 100% 50%; }
 */
 .homepage-hero-module { border-right: none; border-left: none; position: relative; }
 .no-video .video-container video, .touch .video-container video { display: none; }
 .no-video .video-container .poster, .touch .video-container .poster { display: block !important; }
 .video-container { position: relative; bottom: 0%; left: 0%; height: 100%; width: 100%; overflow: hidden; background: #000; }
 .video-container .poster img { width: 100%; bottom: 0; position: absolute; }
 .video-container .filter { z-index: 100; position: absolute; background: rgba(0, 0, 0, 0.4); width: 100%; }
 .video-container video { position: absolute; z-index: 0; bottom: 0; }
 .video-container video.fillWidth { width: 100%; }
 /*video#bgvid { position: fixed; top: 50%; left: 50%; min-width: 100%; min-height: 100%; width: auto; height: auto; z-index: -100; -ms-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); -webkit-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%); background: url(theme/polina.jpg?1615569983) no-repeat; background-size: cover; }*/
 .ytplayer-shield, .vimeoplayer-shield, .uploadplayer-shield { background: #0d042982; }
 .top-bar{ background:#0c0429; width:100%; color: white; display: flex; flex-direction: row; position: absolute; clear:both; font-size: 12px; font-weight: 600; letter-spacing: 0.5px; }
 .events { text-transform:uppercase; display: inline-flex; width: 50%; padding: 5px 20px; }
 .top-bar .events .next-event{ font-weight: 800; padding: 0 7.5px 0 15px; }
 .top-bar .events .next-next-event{ font-weight: 800; color: #8d8d8d; padding: 0 0 0 7.5px; border-left: 1px solid #8c8d8c4d; }
 .top-menu{ display: inline-flex; float: right; padding: 5px 20px; margin-left: auto; }
 .top-menu ul{ list-style: none; }
 .top-menu ul li{ float: left; padding: 0 20px; }
 .top-menu ul li:last-child { padding-right: 0; }
 .top-menu ul li a{ color: white; letter-spacing: normal; }
 .top-menu ul li a:hover{ opacity:0.5; }
 .logo{ padding:20px; }
 .desktop-nav{ padding:20px; }
 @media screen and (max-width: 1024px) {
 .top-bar{ position: relative; }
 .top-menu ul li a { font-size: 10px; }
 .events{ padding: 10px; }
 }
 @media screen and (max-width: 767px) {
 .events{ display: flex; flex-direction: column; width: auto; line-height: normal; }
 .top-bar{ justify-content: space-between; align-items: center; }
 .top-bar .events .next-event{ padding: 0; }
 }
 @media screen and (max-width: 600px) {
 .top-bar{ justify-content: center; align-items: center; flex-direction: column; }
 .events{ display: flex; flex-direction: column; width: 100%; line-height: normal; font-size: 10px; align-items: center; padding: 10px 10px 3px; }
 .top-bar .events .next-event { font-size: 10px; }
 .top-menu { display: inline-flex; float: none; padding: 0 10px 10px; width: 100%; text-align: center; justify-content: center; margin-left: initial; }
 .top-menu ul li:last-child { padding-right: 10px; }
 }
 #cd-vertical-nav_right { position: fixed; left: 40px; top: 50%; bottom: auto; transform: translateY(-50%); z-index: 1; }
 #cd-vertical-nav_right li { text-align: left; list-style:none; }
 #cd-vertical-nav_right a { display: inline-block; -webkit-backface-visibility: hidden; backface-visibility: hidden; }
 #cd-vertical-nav_right a:after { content: ""; display: table; clear: both; }
 #cd-vertical-nav_right a span { display: inline-block; float: left; transform: scale(0.6); }
 #cd-vertical-nav_right a:hover span { transform: scale(1); }
 #cd-vertical-nav_right a:hover .cd-label { opacity: 1; }
 #cd-vertical-nav_right a.is-selected .cd-dot { background-color: black; }
 #cd-vertical-nav_right .cd-dot { position: relative; top: 8px; height: 12px; width: 12px; border-radius: 50%; background-color: #d88683; transition: transform 0.2s, background-color 0.5s; transform-origin: 50% 50%; }
 #cd-vertical-nav_right .cd-label { position: relative; margin-right: 10px; padding: .4em .5em; color: black; font-size: 14px; font-size: 0.875rem; transition: transform 0.2s, opacity 0.2s; opacity: 0; transform-origin: 100% 50%; }
 .wslide-caption-text .author{ color: #2d74be; font-weight: 600; }
 .wslide-caption-text .position{ color: #2d74be; font-size: 16px; }
 @media screen and (max-width: 992px) {
 .wsite-background-394{ display:none; }
 }
 .homepage-hero-module { border-right: none; border-left: none; position: relative; }
 .no-video .video-container video, .touch .video-container video { display: none; }
 .no-video .video-container .poster, .touch .video-container .poster { display: block !important; }
 .video-container { position: relative; bottom: 0%; left: 0%; height: 100%; width: 100%; overflow: hidden; background: #000; }
 .video-container .poster img { width: 100%; bottom: 0; position: absolute; }
 .video-container .filter { z-index: 100; position: absolute; background: rgba(0, 0, 0, 0.4); width: 100%; }
 .video-container video { position: absolute; z-index: 0; bottom: 0; }
 .video-container video.fillWidth { width: 100%; }
 body.page-has-banner.affix .birdseye-header, body.splash-page.affix .birdseye-header { position: fixed; top: 0; background: #190f3d; backface-visibility: hidden; -webkit-backface-visibility: hidden; transition: all 250ms ease; }
 body.page-has-banner.affix .birdseye-header .logo, body.splash-page.affix .birdseye-header .logo { backface-visibility: hidden; -webkit-backface-visibility: hidden; transition: all 250ms ease; max-height:150px; }
 body.page-has-banner.affix .birdseye-header .logo2, body.splash-page.affix .birdseye-header .logo2 { backface-visibility: hidden; -webkit-backface-visibility: hidden; transition: all 250ms ease; padding-top:10px; padding-bottom:10px; }
 body.page-has-banner.affix .logo a, body.splash-page.affix .logo a { color:#fff; }
  body.page-has-banner.affix .nav li > a.wsite-menu-item, body.splash-page.affix .nav li > a.wsite-menu-item { color:#fff; display: block; border-radius: 0px; text-transform: uppercase; letter-spacing: 0.05em; font-size: 13px; font-weight: 700; line-height: normal; transition: all 300ms ease; }
 body.page-has-banner.affix .nav, body.splash-page.affix .nav { display: table-cell; overflow-y: hidden; vertical-align: middle; transition: all 500ms ease; }
 .nav li#active > a.wsite-menu-item, .nav li > a.wsite-menu-item:hover { color:#e9bb00; }
 .nav-wrap { background: #190f3d; }
 body.wsite-native-mobile-editor .birdseye-header { position: absolute !important; }
 .birdseye-header { position: fixed; z-index: 12; overflow-y: hidden; width: 100%; padding: 10px 30px; box-sizing: border-box; backface-visibility: hidden; -webkit-backface-visibility: hidden; transition: all 400ms ease; display: inline-table; }
 .birdseye-header .container { display: table; overflow-y: hidden; width: 100%; max-height: 180px; }
 .birdseye-header label.hamburger { display: none; }
 .birdseye-header .logo { display: table-cell; overflow-y: hidden; margin-right: 30px; padding: 20px; vertical-align: middle; line-height: normal; width: 1px; white-space: nowrap; height:auto; }
 .birdseye-header .logo2 { display: table-cell; overflow-y: hidden; margin-right: 30px; padding: 0; vertical-align: middle; line-height: normal; width: auto; white-space: nowrap; width:1px; height:auto; padding-left:30px; padding-top: 40px; padding-bottom: 40px; overflow-x: hidden; }
 .birdseye-header .logo a { display: block; margin-right: 30px; margin-left: 10px; color: #ffffff; text-transform: uppercase; letter-spacing: 0.03em; font-family: 'Open Sans', sans-serif; font-size: 24px; font-weight: 600; line-height: normal; }
 .birdseye-header .logo img { display: block; overflow: hidden; max-width: 200px; }
 .birdseye-header .logo #wsite-title { display: block; max-width: 400px; font-family: 'Open Sans', sans-serif; font-size: 34px; font-weight: 600; line-height: normal; }
 .nav { display: table-cell; overflow-y: hidden; padding: 20px; vertical-align: middle; transition: all 500ms ease; }
 .nav ul { float: right; overflow: hidden; }
 .nav li { display: inline-block; margin-right: 5px; padding: 0; }
 .nav li > a.wsite-menu-item { display: block; padding: 8px 12px; border: 1px solid transparent; border-radius: 0px; color: #ffffff; text-transform: uppercase; letter-spacing: 0.05em; font-size: 13px; font-weight: 700; line-height: normal; transition: all 300ms ease; }
 .nav.mobile-nav { display: none; }
 #wsite-menus .wsite-menu-wrap { margin-top: 10px; }
 #wsite-menus .wsite-menu { position: relative; }
 #wsite-menus .wsite-menu li a { background: rgba(0,0,0,0.5); color: white; border: none; font-family: 'Open Sans', sans-serif; font-size: 14px; font-weight: normal; line-height: normal; }
 body.page-has-banner.affix #wsite-menus .wsite-menu li a, body.splash-page.affix #wsite-menus .wsite-menu li a { background: rgba(0,0,0,0.5); color: white; border: none; font-family: 'Open Sans', sans-serif; font-size: 14px; font-weight: normal; line-height: normal; }
 body.page-has-banner.affix #wsite-menus .wsite-menu li a:hover, body.splash-page.affix #wsite-menus .wsite-menu li a:hover { background: #e9bb00; color: #0c0429; }
 #wsite-menus .wsite-menu li a:hover { background: #e9bb00; color: #0c0429; }
 #wsite-menus .wsite-menu li > .wsite-menu-wrap { margin-top: 0; }
 #wsite-menus .wsite-menu-arrow { color: transparent; font-size: 0; }
 #wsite-menus .wsite-menu-arrow:before { display: block; color: white; content: '\203A'; font-family: 'Open Sans', sans-serif; font-size: 24px; font-weight: normal; line-height: 11px; }
 #wsite-mini-cart { position: fixed !important; top: 80px !important; overflow-y: scroll; max-height: 600px; box-shadow: none; }
 #wsite-mini-cart .wsite-css-aspect { width: 50px; height: 50px; margin: 10px; }
 .nav li:last-child a.wsite-menu-item, .nav li#pg393890365589558620 a.wsite-menu-item { background: #fff; color: #0c0429!important; }
 .banner-wrap { position: relative; z-index: 6; overflow: hidden; height: 100%; background-size: cover; transition: all 500ms ease; }
 .banner-wrap:before { position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.2); content: ' '; }
 .banner-wrap .container { position: relative; display: table; width: 100%; height: 100%; padding: 80px 0; }
 .banner-wrap .banner { display: table-cell; width: 100%; vertical-align: middle; text-align: center; transition: all 500ms ease; }
 .banner-wrap .banner h2 { padding: 0 0 10px; color: #ffffff; opacity: 1; font-weight: 700; font-size: 70px; line-height: 80px; font-style:normal; letter-spacing:2px; }
 .banner-wrap .banner div.paragraph { padding: 10px 0 25px; color: #ffffff; opacity: 1; font-family: 'Open Sans', sans-serif; font-size: 22px; font-weight: 300; line-height: 32px; }
 .banner-wrap .banner p { padding: 10px 0 25px; color: #ffffff; opacity: 1; font-family: 'Open Sans', sans-serif; font-size: 22px; font-weight: 300; line-height: 32px; }
 .banner-wrap .banner .button-wrap { display: inline-block; }
 .banner-wrap .banner .wsite-button, .banner-wrap .banner .wsite-button-large.wsite-button { text-align: center !important; }
 /*.banner-wrap .scroll-icon:after { content: url(theme/scrolling-icon.png?1615569983); width: 100%; display: table-row-group; }*/
 .main-wrap { width: 100%; min-height: 400px; padding: 70px 0 50px; background: #ffffff; box-sizing: border-box; transition: all 500ms ease; }
 .main-wrap h2 { }
 .main-wrap div.paragraph { font-size: 16px; line-height: 1.5; padding: 10px 0 5px; color: #0c0429; }
 .main-wrap p { font-size: 16px; line-height: 1.5; padding: 10px 0 5px; color: #0c0429; }
 .footer-wrap { width: 100%; background-color: #0c0429; color: #999999; text-align: left; font-size: 13px; display: flex; }
 .footer-wrap .container { width: 100%; }
 .footer-wrap .wsite-footer { width: 100%; max-width: 1300px; margin-left: auto; margin-right: auto; padding: 30px 0 20px; box-sizing: border-box; }
 .footer-wrap h2 { margin: 20px 0 10px; padding-bottom: 10px; color: #ffffff; opacity:0.5; text-transform: uppercase; font-family: 'Open Sans', sans-serif; font-size: 12px; font-weight: 600; line-height: normal; }
 .footer-wrap div.paragraph { color: #999999; font-size: 13px; line-height: 20px; }
 .footer-wrap p { color: #999999; font-size: 13px; line-height: 20px; }
 .footer-wrap blockquote { color: #999999; font-size: 13px; }
 .footer-wrap input[type="radio"], .footer-wrap input[type="checkbox"] { border: none !important; }
 .footer-wrap input[type="radio"]:checked:after { background: #2a92e5; border: 2px solid #181818; }
 .footer-wrap input[type="checkbox"]:after { background: #181818; border: solid #2a92e5; }
 .footer-wrap .wsite-form-container { margin-top: 0; text-align: left; }
 .footer-wrap .wsite-form-input, .footer-wrap .wsite-search-element-input { padding: 10px 6px !important; background: #181818; color: #626262; border: none; border-radius: 5px; font-family: 'Open Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: normal; }
 /*.footer-wrap .form-select { padding: 10px; background: #181818 url(theme/select-dropdown.png?1615569983) 97% center no-repeat; color: #626262; border: none; border-radius: 5px; font-family: 'Open Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: 15px; }*/
 .footer-wrap .wsite-button .wsite-button-inner, .footer-wrap .wsite-button-large .wsite-button-inner { background: transparent; color: #2a92e5 !important; border: 2px solid #2a92e5; }
 .footer-wrap .wsite-button:hover .wsite-button-inner, .footer-wrap .wsite-button-large:hover .wsite-button-inner { background: #2a92e5; color: #ffffff !important; }
 .footer-wrap .paragraph a { color: white; padding: 2px 0; margin: 5px 0; font-weight: 500; font-size: 14px; display: inline-block; border-bottom: 1px solid transparent; }
 .footer-wrap .paragraph a:hover { border-bottom: 1px solid; }
 .wsite-social-item { display: inline-block !important; width: 30px !important; height: 30px !important; margin-right: 10px; background: rgba(0,0,0,0.0); border-radius: 15px; text-align: center; transition: all 300ms ease; vertical-align :middle; color: #fff ; }
 .wsite-social-item:before { position: relative; top: 0.2em; font-size: 16px; }
 .wsite-social-item:hover { background: #fff; color: #f8da94 !important; }
 .wsite-editor .wsite-social .wsite-social-item.hidden { display: none !important; }

 
 .imageGallery .galleryImageHolder .fullImageGalleryCaption, .imageGallery .galleryImageHolder .partialImageGalleryCaption { height: 100%; }
 .imageGallery .galleryImageHolder .fullImageGalleryCaption .galleryCaptionHolderInnerBg, .imageGallery .galleryImageHolder .partialImageGalleryCaption .galleryCaptionHolderInnerBg { background: #232323; opacity: 0.2; bottom: 0; border-radius: 10px; transition: all 300ms ease; }
 img.galleryImage { border-radius: 10px; }
 .imageGallery .galleryImageHolder .fullImageGalleryCaption .galleryCaptionHolderInner, .imageGallery .galleryImageHolder .partialImageGalleryCaption .galleryCaptionHolderInner { position: absolute; top: auto; bottom: 0; }
 .imageGallery .galleryImageHolder:hover .fullImageGalleryCaption .galleryCaptionHolderInnerBg, .imageGallery .galleryImageHolder:hover .partialImageGalleryCaption .galleryCaptionHolderInnerBg { opacity: 0.4; }
 .imageGallery .galleryCaptionInnerText { color: #ffffff; letter-spacing: normal; text-shadow: none; font-family: 'Open Sans', sans-serif; font-size: 16px; font-weight: 400; line-height: 24px; text-align:left; }
 .galleryImageBorder{ border-radius:10px; }
 .wsite-image-border-thick a img { border: none!important; padding: 0!important; }
 .wsite-image-border-thick { padding: 0!important; }
 .galleryCaptionInnerText .award, .wsite-image div .award{ text-transform: uppercase; font-size: 13px; font-weight: 600; color: #cecece; }
 .galleryCaptionInnerText h3, .wsite-image div h3{ font-size: 24px; padding: 5px 0 0; }
 .imageGallery .galleryCaptionInnerText{ text-align: left; }
 .galleryCaptionHolderInner.galleryCaptionsVisible { background: linear-gradient(360deg, rgba(1, 0, 14, 0.8) 0%, rgba(0, 0, 10, 0.40) 55%, rgba(0, 0, 16, 0) 100%)!important; border-radius: 10px!important; padding-top: 30px; }
 .wsite-image-border-none a img { border-radius: 10px; }
 .melhor-jogo { text-align: left; padding:20px; bottom: 7px; left: 0; right: 0; position: absolute; margin: auto; color: white; background: linear-gradient(360deg, rgba(1, 0, 14, 0.8) 0%, rgba(0, 0, 16, 0) 100%)!important; border-radius: 10px!important; }
 .wsite-form-label { display: inline-block; padding: 0 0 5px; color: #626262; font-family: 'Open Sans', sans-serif; font-size: 15px; font-weight: 400; line-height: 26px; }
 .wsite-form-sublabel { display: none; }
 .wsite-form-radio-container { margin-bottom: 15px; }
 .form-radio-container { color: #626262; font-family: 'Open Sans', sans-serif; font-size: 15px; font-weight: 300; line-height: normal; }
 .wsite-form-input, .wsite-search-element-input { height: auto; padding: 8px !important; margin-bottom: 15px; background: #fafafa; color: #626262; border: 1px solid #cccccc; border-radius: 2px; font-family: 'Open Sans', sans-serif; font-size: 15px; font-weight: 300; line-height: normal; transition: all 300ms ease; }
 .wsite-form-input:focus, .wsite-form-input--focus, .wsite-search-element-input:focus { border-color: #a1a1a1; }
 .wsite-search-element { margin-bottom: 15px; }
 .wsite-search-element .wsite-search-element-input { margin-bottom: 0; }
 /*.form-select, .wsite-form-field select, .wsite-com-product-option-groups select { height: 40px; padding: 8px; background: #fafafa url(theme/select-dropdown.png?1615569983) 97% center no-repeat; color: #626262; border: 1px solid #cccccc; border-radius: 2px; box-sizing: border-box; font-family: 'Open Sans', sans-serif; font-size: 15px; font-weight: 300; line-height: normal; }*/
 .form-select:focus, .wsite-form-field select:focus, .wsite-com-product-option-groups select:focus { border-color: #a1a1a1; }
 .formlist, .wsite-editor .formlist { min-height: inherit; }
 .wsite-form-container .form-input-error { border: 1px solid red !important; }
 .form-field-error .wsite-form-radio-container { border: none; }
 .wsite-button, .wsite-editor .wsite-button{ display: inline-block; height: auto; background: transparent; color: #0c0429; text-transform: uppercase; letter-spacing: 0.02em; white-space: normal; font-family: 'Open Sans', sans-serif; font-weight: 600!important; line-height: normal; transition: all 500ms ease; padding: 0; }
 .wsite-button-inner{ background: transparent; padding: 0; transition: all 500ms ease; }
 .wsite-button:focus, .wsite-editor .wsite-button:focus { outline: none; }
 .wsite-button-large .wsite-button-inner{ font-size: 20px; }
 .wsite-button-small .wsite-button-inner{ font-size: 16px; }
 .wsite-button-normal .wsite-button-inner{ border-bottom: 2px solid #0c0429; background: none!important; }
 .wsite-button-highlight .wsite-button-inner{ background: #e9bb00!important; color:#0c0429; padding: 5px 24px; border: 2px solid #e9bb00; }
 .wsite-button-normal:hover .wsite-button-inner{ border-bottom: 2px solid #e9bb00; }
 .wsite-button-highlight:hover .wsite-button-inner{ background: #1e045d!important; padding: 5px 24px; border: 2px solid #1a075d; color: #fff; }
 .wsite-button.wsite-button-small.wsite-button-highlight .wsite-button-inner{ background: none!important; color:white; border: 2px solid white; }
 a.wsite-button.wsite-button-large.wsite-button-normal .wsite-button-inner { color: #fff; border-bottom: 2px solid #fff; padding: 0; }
 a:hover.wsite-button.wsite-button-large.wsite-button-normal .wsite-button-inner { border-bottom: 2px solid #e9bb00; }
 .wslide-content{ height: 400px!important;; }
 .wslide-slide-inner1 { left: 27%!important; }
 .wslide-slide-inner2 img { border-radius: 50%!important; }
 .wslide-caption { left: 350px!important; width: 500px!important; }
 .wslide-link-number{ background: #e6e5f8!important; height: 10px!important; width: 10px!important; }
 .wslide-link-number>.wslide-link-inner1{ background: #190f3d; border-radius: 50%; overflow: hidden; display: block; font-size: 0px; line-height: 10px; }
 .wslide-links-inner { position: inherit!important; left: 42%!important; }
 .wslide-caption-text{ text-shadow: none!important;; font-size: 18px; color: #0d0429; }
 .wslide-caption-bg { background: none!important; }
 .wslide-links-bottom .wslide-link { margin: 0 !important; }
 .wsite-com-checkout-section .wsite-form-field select, .wsite-com-checkout-section .wsite-form-field input, .wsite-com-checkout-section .wsite-form-field .select2-container { height: auto; }
 .fancybox-skin { background: transparent !important; box-shadow: none !important; }
 .fancybox-title { color: white; font-family: 'Open Sans', sans-serif; font-size: 15px; font-weight: normal; line-height: normal; }
 .fancybox-close, .fancybox-next span, .fancybox-prev span { background: none !important; width: auto; height: auto; }
 .fancybox-close:before, .fancybox-next span:before, .fancybox-prev span:before { color: white !important; font-family: 'Open Sans', sans-serif; font-size: 45px; font-weight: 400; line-height: 0.75em; transition: all 300ms ease; }
 .fancybox-close:hover:before, .fancybox-next:hover span:before, .fancybox-prev:hover span:before { color: #f8da94 !important; }
 .fancybox-prev span:before { position: relative; left: -10px; content: '\3008'; }
 .fancybox-next span:before { position: relative; right: -10px; content: '\3009'; }
 .fancybox-close { top: 20px; right: 20px; }
 .fancybox-close:before { content: '\00D7'; }
 .landing-page.fade-in .logo, .landing-page.fade-in .desktop-nav, .landing-page.fade-in .banner-wrap .container { opacity: 1; }
 .landing-page.fade-on-scroll .banner { opacity: 1; }
 .landing-page .logo { opacity: 0; transition: all 1000ms ease-in; }
 .landing-page .fader { opacity: 1; transition: all 1000ms ease-in; }
 .landing-page .fade { opacity: 1; }
 .landing-page .desktop-nav { opacity: 0; transition: all 1000ms ease-in; }
 .landing-page .banner-wrap { min-height: 630px; height:100vh; }
 .landing-page .banner-wrap .container { overflow-y: hidden; height: 80vh; opacity: 0; transition: all 1000ms ease-in; }
 .landing-page .banner { margin-top: 40px; }
 .tall-header-page .banner-wrap { min-height: 450px; transition: all 250ms ease-in-out; }
 .tall-header-page .banner-wrap .container { overflow-y: hidden; height: 290px; }
 .tall-header-page .banner h2 { font-size: 50px; line-height: 64px; padding-bottom: 0; }
 .short-header-page .banner-wrap { min-height: 240px; transition: all 250ms ease-in-out; }
 .short-header-page .banner-wrap .container { overflow-y: hidden; padding: 80px 0 60px; height: 100px; }
 .short-header-page .banner h2 { font-size: 40px; line-height: 52px; padding-bottom: 0; }
 body.page-has-banner.no-header-page .birdseye-header { position: relative; top: 0; padding-top: 10px; padding-bottom: 10px; background: rgba(35, 35, 35, 0.95); background-image: none !important; }
 body.page-has-banner.no-header-page.affix .birdseye-header { position: fixed; }
 .splash-page { position: relative; top: 0; left: 0; width: 100%; height: 100%; min-height: 100vh; background: none; color: #ffffff; box-sizing: border-box; }
 .splash-page .wrapper { height: 100%; }
 .splash-page .birdseye-header { padding-top: 20px; padding-bottom: 20px; background: none; background-image: none !important; box-sizing: border-box; }
 /*.splash-page .main-wrap { position: relative; display: table; width: 100%; height: 100%; min-height: 100vh; background: url(theme/default-bg.jpg?1615569983) no-repeat; background-attachment: fixed !important; background-size: cover; box-sizing: border-box; }*/
 .splash-page .main-wrap:before { position: absolute; top: 0; left: 0; display: block; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.1); content: ' '; }
 .splash-page .main-wrap > .container { position: relative; display: table; height: 100%; box-sizing: border-box; }
 .splash-page .main-wrap .content-wrap { display: table-cell; vertical-align: middle; }
 .splash-page .main-wrap .splash-content { margin: 0 auto; }
 .splash-page .main-wrap h2 { color: #ffffff; font-size: 60px; }
 .splash-page .main-wrap div.paragraph, .splash-page .main-wrap div.paragraph { color: #ffffff; font-size: 20px; }
 .splash-page .main-wrap p, .splash-page .main-wrap div.paragraph { color: #ffffff; font-size: 20px; }
 .splash-page .main-wrap .wsite-button .wsite-button-inner, .splash-page .main-wrap .wsite-button-large.wsite-button .wsite-button-inner { background: #a1a1a1; color: white !important; border: 2px solid #a1a1a1; font-weight: 600; }
 .splash-page .main-wrap .wsite-button:hover .wsite-button-inner, .splash-page .main-wrap .wsite-button-large:hover .wsite-button-inner { background: transparent; color: #a1a1a1; }
 .splash-page .main-wrap .wsite-button-highlight .wsite-button-inner, .splash-page .main-wrap .wsite-button-large.wsite-button-highlight .wsite-button-inner { background: transparent; color: #ffffff !important; border: solid 2px #ffffff; font-weight: 600; }
 .splash-page .main-wrap .wsite-button-highlight:hover .wsite-button-inner, .splash-page .main-wrap .wsite-button-large.wsite-button-highlight:hover .wsite-button-inner { background: #ffffff; color: #232323 !important; }
 .splash-page .footer-wrap, .splash-page .wsite-footer { display: none; }
 #blogTable .blog-post .blog-header h2.blog-title { margin-bottom: 20px !important; }
 #blogTable .blog-post .blog-header h2.blog-title a { color: #333333; }
 #blogTable .blog-sidebar h2 { font-size: 18px; margin-bottom: 10px; }
 #blogTable .blog-sidebar div.paragraph { margin-bottom: 20px; }
 #blogTable .blog-sidebar p { margin-bottom: 20px; }
 .blogCommentReplyWrapper iframe { height: 560px !important; }
 #commentPostDiv .field input[type=text], #commentPostDiv .field textarea { height: auto; padding: 8px 12px; background: transparent; box-sizing: border-box; transition: all 300ms ease; }
 #commentPostDiv .field input[type=text]:focus, #commentPostDiv .field textarea:focus { border: 1px solid #2a92e5; }
 #commentSubmit { height: auto; padding: 8px 12px; background: #a1a1a1; color: white; border: 2px solid #a1a1a1; border-radius: 1px; text-transform: uppercase; white-space: normal; font-family: 'Open Sans', sans-serif; font-size: 14px; font-weight: 700; line-height: normal; transition: all 300ms ease; }
 #commentSubmit span { background: none; }
 #commentSubmit:hover { background: transparent; color: #a1a1a1 !important; }
 #commentArea { border-top: solid 1px #dadada; padding-top: 20px; }
 #wsite-content #commentAreaTitle, #wsite-content #commentReplyTitle, #commentArea .blogCommentText div.paragraph, .main-wrap .blog-sidebar h2 { padding: 0; }
 #wsite-content #commentAreaTitle, #wsite-content #commentReplyTitle, #commentArea .blogCommentText p, .main-wrap .blog-sidebar h2 { padding: 0; }
 #commentAreaTitle, .blogCommentHeading, .blogCommentText div.paragraph { font-family: inherit; }
 #commentAreaTitle, .blogCommentHeading, .blogCommentText p { font-family: inherit; }
 .blogCommentWrap { padding: 20px; border: solid 1px #ccc; border-radius: 6px; background: none; }
 .blogCommentWrap .blogCommentHeading, .blogCommentWrap .blogCommentHeadingInner, .blogCommentWrap .blogCommentAuthor { background: none !important; }
 .blogCommentHeading .blogCommentAuthor span.name, .blogCommentHeading .blogCommentAuthor span.email { color: #2a92e5 !important; float: none; line-height: 1; }
 .blogCommentHeading .blogCommentAuthor { margin-bottom: 10px; }
 .blogCommentHeading { padding: 0; margin-bottom: 20px; }
 .blogCommentHeading .blogCommentHeadingInner { height: auto; }
 .blogCommentHeading .blogCommentAuthor, .blogCommentHeading .blogCommentDate { float: none; text-align: left; height: auto; line-height: 1; padding: 0; }
 .blogCommentHeading .blogCommentAuthor { text-transform: uppercase; }
 .blogCommentHeading .blogCommentDate { color: #333; }
 .blogCommentText { padding: 0; }
 .blogCommentText div.paragraph { color: #666; font-size: 14px; }
 .blogCommentText p { color: #666; font-size: 14px; }
 .blogCommentOptions .blog-button { height: auto; padding: 8px 12px; background: #a1a1a1; color: white; border: 2px solid #a1a1a1; border-radius: 1px; text-transform: uppercase; white-space: normal; font-family: 'Open Sans', sans-serif; font-size: 14px; font-weight: 700; line-height: normal; transition: all 300ms ease; }
 .blogCommentOptions .blog-button span { background: none; }
 .blogCommentOptions .blog-button:hover { background: transparent; color: #a1a1a1 !important; }
 #wsite-com-store .wsite-com-category-subcategory-image-wrap { height: 100%; border: none; }
 #wsite-com-store .wsite-com-category-subcategory-name { top: 0; }
 /*#wsite-com-store .wsite-com-category-subcategory-name-bg { background-color: rgba(0, 0, 0, 0.1); background-image: linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)); background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)); background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)); background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)); background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)); }*/
 #wsite-com-store .wsite-com-category-subcategory-name-text { position: absolute; bottom: 0; width: 100%; background: none; box-sizing: border-box; text-shadow: none; font-weight: 400; }
 #wsite-com-store #wsite-com-product-title { font-weight: inherit; }
 #wsite-com-store .wsite-com-category-subcategory-link .wsite-com-category-subcategory-image-wrap, #wsite-com-store .wsite-com-category-product-link .wsite-com-category-product-image-wrap, #wsite-com-store .wsite-com-category-product-link-featured .wsite-com-category-product-featured-image-wrap { opacity: 1; transition: all 300ms ease; }
 #wsite-com-store .wsite-com-category-subcategory-link:hover .wsite-com-category-subcategory-image-wrap, #wsite-com-store .wsite-com-category-product-link:hover .wsite-com-category-product-image-wrap, #wsite-com-store .wsite-com-category-product-link-featured:hover .wsite-com-category-product-featured-image-wrap { opacity: .7; }
 #wsite-com-store #wsite-com-hierarchy ul { font-size: 16px; }
 #wsite-com-store #wsite-com-hierarchy ul .wsite-link-unselected { color: #2a92e5; }
 #wsite-com-store #wsite-com-hierarchy ul .wsite-link-unselected:hover { color: #2180cc; }
 .wsite-product { border-radius: 0; box-shadow: none; }
 h2#wsite-com-product-title { display: block; clear: both; font-weight: 400; }
 .wsite-com-product-title { font-weight: 400; }
 #wsite-com-breadcrumbs.wsite-com-product-breadcrumbs, #wsite-com-breadcrumbs.wsite-com-category-breadcrumbs { padding-bottom: 25px; }
 .wsite-com-category-product-group .wsite-com-category-product-wrap, .wsite-com-category-product-featured-group .wsite-com-category-product-wrap, .wsite-com-category-product-featured-group .wsite-com-category-product-featured-wrap, .wsite-com-category-subcategory-group .wsite-com-category-subcategory-wrap { font-size: 1em; }
 #wsite-com-product-info { float: left; width: 50%; margin-left: 30px; }
 .wsite-product-button-wrap { display: block; text-align: left; }
 .wsite-com-checkout-section .wsite-form-field select, .wsite-com-checkout-section .wsite-form-field input, .wsite-com-checkout-section .wsite-form-field .select2-container { height: 40px; }
 .wsite-com-checkout-section .wsite-form-field input[type="radio"] { display: inline-block; margin: 0 5px 0 0; width: 16px; height: 16px; }
 .wsite-com-checkout-section .wsite-form-field select { -webkit-appearance: none; appearance: none; }
 @media screen and (min-width: 992px) {
 #navMobile { display:none; }
 }
 @media screen and (max-width: 992px) {
 .landing-page .banner-wrap { height: auto !important; }
 h2 { color: #0c0429; font-family: 'Open Sans', serif; font-size: 1.5em; font-weight: 600; }
 body.page-has-banner .birdseye-header, body.page-has-banner.affix .birdseye-header, body.splash-page.affix .birdseye-header { padding: 5px 20px; }
 body.page-has-banner .birdseye-header .logo, body.page-has-banner.affix .birdseye-header .logo, body.splash-page.affix .birdseye-header .logo { max-height: 40px; }
 body.page-has-banner .birdseye-header .logo img, body.page-has-banner.affix .birdseye-header .logo img, body.splash-page.affix .birdseye-header .logo img { max-height: 40px; }
 body.page-has-banner .main-wrap, body.page-has-banner.affix .main-wrap, body.splash-page.affix .main-wrap { padding-top: 40px; }
 body.cart-full #wsite-mini-cart { display: block !important; }
 body.cart-full .wsite-footer { padding-bottom: 70px; }
 .container { width: 100%; }
 .nav-open .nav.mobile-nav { display: block; padding: 50px 0 !important; max-height: 100vh; }
 body.page-has-banner.affix .nav li > a.wsite-menu-item, body.splash-page.affix .nav li > a.wsite-menu-item { color:white ; display: block; padding: 10px 12px !important; border: 1px solid transparent; border-radius: 0px; text-transform: uppercase; letter-spacing: 0.05em; font-family: 'Open Sans', sans-serif; font-size: 13px; font-weight: 700; line-height: normal; transition: all 300ms ease; }
 body.page-has-banner.affix .nav, body.splash-page.affix .nav { display: table-cell; overflow-y: hidden; vertical-align: middle; transition: all 500ms ease; }
 .nav-open .birdseye-header label.hamburger span { background: transparent; }
 .nav-open .birdseye-header label.hamburger span:before { top: 1px; transform: rotate(45deg); }
 .nav-open .birdseye-header label.hamburger span:after { top: -1px; transform: rotate(135deg); }
 body.splash-page.nav-open .wrapper .main-wrap { position: relative; }
 .birdseye-header { min-height: 50px; padding: 0!important; background: #190f3d; }
 .birdseye-header .logo { overflow: hidden; padding-right: 25px; padding-top:10px; padding-bottom:10px; }
 body.page-has-banner.affix .birdseye-header .logo, body.splash-page.affix .birdseye-header .logo { padding-top:10px; padding-bottom:10px; backface-visibility: hidden; -webkit-backface-visibility: hidden; transition: all 250ms ease; }
 body.page-has-banner.affix .birdseye-header, body.splash-page.affix .birdseye-header { position: fixed; top: 0; background: #190f3d; padding: 10px 30px; border-bottom:1px solid rgba(0,0,0,0.2); backface-visibility: hidden; -webkit-backface-visibility: hidden; transition: all 250ms ease; }
 body.page-has-banner.affix .logo a, body.splash-page.affix .logo a { color:white; }
 .birdseye-header .logo2 { display:none; }
 .birdseye-header .logo a { margin-left: 0; margin-right: 0; font-size: 1.1em; line-height: 1.4em; }
 .birdseye-header .logo img { max-height: 40px; }
 .birdseye-header .logo #wsite-title { display: block; max-width: 100%; font-size: 1.1em !important; line-height: 1.4em !important; }
 .birdseye-header .container { min-height: 40px; max-height: inherit; }
 .birdseye-header label.hamburger { display: table-cell; vertical-align: middle; }
 label.hamburger { position: absolute; top: 0; right: 10px; width: 30px; height: 30px; padding: 10px; cursor: pointer; transition: all 300ms ease-in-out; }
 label.hamburger span, label.hamburger span:before, label.hamburger span:after { position: relative; display: block; width: 22px; height: 2px; background: #ffffff; content: ''; transition: all 200ms ease-in-out; }
 label.hamburger span { top: 9px; left: 4px; margin: 5px 0; }
 label.hamburger span:before { top: -8px; }
 label.hamburger span:after { bottom: -6px; }
 .desktop-nav { display: none !important; }
 .mobile-nav { position: fixed; top: 0; left: 0; z-index: 12; display: block; overflow-x: hidden; overflow-y: auto; width: 100vw; height: 100vh; max-height: 0; padding: 0; background: #190f3d; box-sizing: border-box; transition: all 300ms cubic-bezier(0, 0.085, 0.68, 0.53); }
 .mobile-nav label.hamburger span { background: transparent; }
 .mobile-nav label.hamburger span:before { top: 1px; transform: rotate(45deg); }
 .mobile-nav label.hamburger span:after { top: -1px; transform: rotate(135deg); }
 .mobile-nav ul { float: none; height: auto; }
 .mobile-nav li { position: relative; display: block !important; margin: 0; border: none; text-align: left; }
 .mobile-nav li .wsite-menu-link-styles { position: relative; padding: 10px 0; border: none; }
 .mobile-nav li > a.wsite-menu-item { position: relative; padding: 10px 20px; border: none; }
 .mobile-nav li > a.wsite-menu-subitem { display: block; position: relative; padding: 10px 30px; border: none; color: #fff; text-transform: none; font-family: 'Open Sans', sans-serif; font-size: 14px; font-weight: 400; line-height: normal; transition: all 300ms ease; }
 .mobile-nav li .wsite-menu-wrap { display: block !important; overflow: hidden; max-height: 0; transition: all 500ms ease-in-out 250ms; }
 .mobile-nav li .wsite-menu-wrap li.wsite-nav-current > a.wsite-menu-subitem { border: none; }
 .mobile-nav li .wsite-menu-wrap .wsite-menu-arrow { display: none; }
 .mobile-nav li.wsite-menu-item-wrap#active > a.wsite-menu-item, .mobile-nav li.wsite-menu-item-wrap > a.wsite-menu-item:hover, .mobile-nav li.wsite-menu-subitem-wrap > a.wsite-menu-subitem:hover { border: none; }
 .mobile-nav li.has-submenu > span.icon-caret { position: absolute; top: 0; right: 0; display: block; width: 20px; height: 20px; padding: 10px 20px; cursor: pointer; transition: all 300ms ease-in-out; }
 .mobile-nav li.has-submenu > span.icon-caret:before { position: relative; display: block; color: white; font-size: 24px; content: '\203A'; transform: rotate(90deg); }
 .mobile-nav li.has-submenu .wsite-menu-wrap.open { max-height: 1000px; }
 .mobile-nav li.wsite-nav-more { display: none !important; }
 .banner-wrap { padding: 0 40px; }
 .banner-wrap .container { padding: 110px 0 60px; }
 .banner-wrap .banner h2 { font-family: 'Open Sans', serif; font-size: 50px; font-weight: 400; line-height: 60px; }
 .wsite-section-bg-video .wsite-section-content { background: #0d0429bd; }
 .footer-wrap .wsite-footer { width: 100%; padding: 10px 20px 20px; box-sizing: border-box; }
 .footer-wrap td.wsite-multicol-col { margin-bottom: 25px !important; }
 .footer-wrap td.wsite-multicol-col:last-child { width: 100% !important; }
 #wsite-mini-cart { position: fixed !important; top: initial !important; bottom: 0px; left: 0 !important; z-index: 9; width: 100%; border: none; border-top: 1px solid; border-radius: 0; backface-visibility: hidden; -webkit-backface-visibility: hidden; transition: all 300ms ease-in-out; }
 #wsite-mini-cart.arrow-top:before, #wsite-mini-cart.arrow-top:after, #wsite-mini-cart.arrow-left:before, #wsite-mini-cart.arrow-left:after { border: none !important; }
 #wsite-mini-cart .wsite-product-list { display: none; }
 .landing-page .banner-wrap { min-height: 400px; }
 .no-header-page .birdseye-header { padding: 5px 20px; }
 .no-header-page .main-wrap { padding-top: 80px; }
 .splash-page .birdseye-header { padding: 5px 20px; background: rgba(35, 35, 35, 0.95); }
 .splash-page .main-wrap { padding: 4em 1.5em; }
 .splash-page .main-wrap .splash-content { width: 100%; }
 .splash-page .main-wrap h2 { font-family: 'Raleway', serif; font-size: 2.5em; font-weight: 400; line-height: 1.3em; }
 .splash-page .main-wrap div.paragraph, .splash-page .main-wrap div.paragraph { font-size: 16px; line-height: 26px; }
 .splash-page .main-wrap p, .splash-page .main-wrap div.paragraph { font-size: 16px; line-height: 26px; }
 }
 @media screen and (max-width: 767px) {
 .container { width: 100%; }
 .banner-wrap { padding: 0 20px; box-sizing: border-box; }
 .banner-wrap .container { padding: 80px 0 30px; }
 .banner-wrap .banner h2 { padding-bottom: 20px; font-family: 'Raleway', serif; font-size: 250%; font-weight: 400; line-height: 1.25em; }
 h2 .wsite-headline, div.paragraph .wsite-headline-paragraph { font-size: inherit !important; line-height: inherit !important; }
 h2 .wsite-headline, p .wsite-headline-paragraph { font-size: inherit !important; line-height: inherit !important; }
 .galleryCaptionHolder { display: block; }
 .imageGallery > div{ width: 100%!important; }
 .fullwidth-mobile { width: 100% !important; }
 .fullwidth-mobile .galleryCaptionHolder { display: block !important; }
 td.wsite-multicol-col { float: none; display: block; width: 100% !important; box-sizing: border-box; }
 .wsite-background-50 .group-box-content .wsite-multicol-table td.wsite-multicol-col { width: 33.333333%!important; float: left; }
 #blogTable td { float: none; display: block; width: 100%; margin: 0; padding: 0; }
 #blogTable td.blog-sidebar { width: 100%; }
 #blogTable td.blog-sidebar div.paragraph { margin-bottom: 0; }
 #blogTable td.blog-sidebar p { margin-bottom: 0; }
 #blogTable td .blog-body { float: none !important; }
 #blogTable td .column-blog { width: 100%; float: none; }
 #blogTable td .blog-sidebar-separator { padding: 0; }
 #wsite-com-store { }
 #wsite-com-store .wsite-com-category-subcategory-group .wsite-com-column, #wsite-com-store .wsite-com-category-product-featured-group .wsite-com-column, #wsite-com-store .wsite-com-category-product-group .wsite-com-column { float: left; width: 50% !important; }
 #wsite-com-store .wsite-com-sidebar { position: relative; float: none !important; z-index: 6; width: 100%; min-height: 35px; margin: 0 auto 15px; padding: 0; text-align: center; }
 #wsite-com-store .wsite-com-sidebar:before { display: block; width: 100%; padding: 10px 18px; box-sizing: border-box; text-align: center; text-transform: uppercase; content: 'VIEW ALL CATEGORIES\00a0\00a0\25be'; font-weight: 700; cursor: pointer; }
 #wsite-com-store .wsite-com-sidebar #wsite-com-hierarchy { position: relative; display: block; overflow: hidden; width: 100%; max-height: 0px; padding: 0; background: white; box-sizing: border-box; transition: all 300ms ease-in-out; }
 #wsite-com-store .wsite-com-sidebar #wsite-com-hierarchy > ul { width: 100%; padding: 0; }
 #wsite-com-store .wsite-com-sidebar #wsite-com-hierarchy > ul li { margin-bottom: 15px; }
 #wsite-com-store .wsite-com-sidebar.sidebar-expanded { cursor: default !important; }
 #wsite-com-store .wsite-com-sidebar.sidebar-expanded:before { content: 'CLOSE\00a0\00a0\25b4'; }
 #wsite-com-store .wsite-com-sidebar.sidebar-expanded #wsite-com-hierarchy { max-height: 100vh; padding: 10px 0; }
 div.wsite-product-image-wrap { width: 100% !important; }
 .wsite-product .wsite-product-top { width: 100%; }
 .wsite-product .wsite-product-button-wrap { width: auto; }
 #wsite-com-product-images { float: none; width: 100%; margin-bottom: 20px; }
 #wsite-com-product-info { width: 100%; margin-left: 0; }
 #wsite-com-product-images-strip .wsite-com-product-images-secondary-outer { margin-bottom: 10px; }
 #wsite-search-sidebar { position: relative; z-index: 6; overflow-y: hidden; width: 100%; max-height: 35px; padding: 0; box-sizing: border-box; transition: all 500ms ease-in-out; }
 #wsite-search-sidebar::before { display: block; width: 100%; padding: 10px 18px; box-sizing: border-box; text-align: center; text-transform: uppercase; content: 'VIEW SEARCH FILTERS\00a0\00a0\25be'; font-weight: 700; cursor: pointer; }
 #wsite-search-sidebar.sidebar-expanded { max-height: 1000px; cursor: default !important; }
 #wsite-search-sidebar.sidebar-expanded:before { content: 'CLOSE\00a0\00a0\25b4'; }
 #wsite-search-header h2 { width: 100%; margin-bottom: 10px; font-size: 1.6em; }
 #wsite-search-form-container { float: none; width: 100%; }
 #wsite-search-query { padding: 2px 10px; }
 #wsite-search-sidebar .wsite-search-facet-price input { width: 100%; margin: 10px 0; padding: 6px!important; }
 #wsite-search-results { width: 100%; }
 #wsite-search-product-results .wsite-search-product-result { width: 100%; padding-right: 0; }
 #wsite-com-checkout-list .wsite-form-input, #wsite-com-checkout-summary-list .wsite-form-input { width: 20px; }
 #wsite-com-checkout-list .wsite-form-input.wsite-coupon-input, #wsite-com-checkout-summary-list .wsite-form-input.wsite-coupon-input { width: 95px; }
 #wsite-com-checkout-list td { padding: 10px 0 20px; }
 #wsite-com-checkout-list .wsite-remove-button, #wsite-com-checkout-summary-list .wsite-remove-button { top: 44px; left: -10px; }
 .wsite-com-continue-shopping .caret { display: inline-block; margin-top: 2px; }
 .wsite-com-checkout-payment-column, .wsite-com-checkout-summary-column { float: none; width: 100%; }
 }
 @media screen and (max-width: 480px) {
 #wsite-com-store .wsite-com-category-subcategory-group .wsite-com-column, #wsite-com-store .wsite-com-category-product-featured-group .wsite-com-column, #wsite-com-store .wsite-com-category-product-group .wsite-com-column { float: none; width: 100% !important; }
 #wsite-com-checkout-list .wsite-com-checkout-item-image, #wsite-com-checkout-summary-list .wsite-com-checkout-item-image { display: none; }
 #wsite-com-checkout-list tbody td:first-child, #wsite-com-checkout-summary-list tbody td:first-child { width: 0; padding: 0; }
 #wsite-com-checkout-list .wsite-com-checkout-item-details-inner, #wsite-com-checkout-summary-list .wsite-com-checkout-item-details-inner { padding-left: 0; }
 }

div.paragraph ul, div.paragraph ol { padding-left: 3em !important; margin: 5px 0 !important; }
div.paragraph li { padding-left: 5px !important; margin: 3px 0 0 !important; }
div.paragraph ul, div.paragraph ul li { list-style: disc outside !important; }
div.paragraph ol, div.paragraph ol li { list-style: decimal outside !important; }

@font-face {
    font-family: "wsocial";
    src: url(//cdn2.editmysite.com/fonts/wSocial/wsocial.eot?ts=1615504613783);
    src: url(//cdn2.editmysite.com/fonts/wSocial/wsocial.eot?ts=1615504613783#iefix) format("embedded-opentype"), url(//cdn2.editmysite.com/fonts/wSocial/wsocial.woff?ts=1615504613783) format("woff"),
        url(//cdn2.editmysite.com/fonts/wSocial/wsocial.ttf?ts=1615504613783) format("truetype"), url(//cdn2.editmysite.com/fonts/wSocial/wsocial.svg?ts=1615504613783#wsocial) format("svg");
    font-weight: normal;
    font-style: normal;
}

.wsite-social-item:before {
    font-family: "wsocial";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
}

.wsite-social-dribbble:before {
    content: "\e60c";
}
.wsite-com-product-social-dribbble:before {
    content: "\e60c";
}
.wsite-social-color .wsite-social-dribbble:before {
    content: "\e60c";
    color: #f077a0;
}
.wsite-social-square .wsite-social-dribbble,
.wsite-social-square.wsite-social-dribbble {
    background-color: #f077a0;
}
.wsite-social-square .wsite-social-dribbble:after,
.wsite-social-square.wsite-social-dribbble:after {
    content: "\e60c";
    color: #ffffff;
}
.wsite-social-mail:before {
    content: "\e603";
}
.wsite-com-product-social-mail:before {
    content: "\e603";
}
.wsite-social-color .wsite-social-mail:before {
    content: "\e603";
    color: #363b3e;
}
.wsite-social-square .wsite-social-mail,
.wsite-social-square.wsite-social-mail {
    background-color: #363b3e;
}
.wsite-social-square .wsite-social-mail:after,
.wsite-social-square.wsite-social-mail:after {
    content: "\e603";
    color: #ffffff;
}
.wsite-social-facebook:before {
    content: "\e600";
}
.wsite-com-product-social-facebook:before {
    content: "\e600";
}
.wsite-social-color .wsite-social-facebook:before {
    content: "\e600";
    color: #3b5998;
}
.wsite-social-square .wsite-social-facebook,
.wsite-social-square.wsite-social-facebook {
    background-color: #3b5998;
}
.wsite-social-square .wsite-social-facebook:after,
.wsite-social-square.wsite-social-facebook:after {
    content: "\e600";
    color: #ffffff;
}
.wsite-social-flickr-left:before {
    content: "\e60d";
}
.wsite-com-product-social-flickr-left:before {
    content: "\e60d";
}
.wsite-social-color .wsite-social-flickr-left:before {
    content: "\e60d";
    color: #0063dc;
}
.wsite-social-square .wsite-social-flickr-left,
.wsite-social-square.wsite-social-flickr-left {
    background-color: #0063dc;
}
.wsite-social-square .wsite-social-flickr-left:after,
.wsite-social-square.wsite-social-flickr-left:after {
    content: "\e60d";
    color: #ffffff;
}
.wsite-social-flickr-right:before {
    content: "\e60e";
}
.wsite-com-product-social-flickr-right:before {
    content: "\e60e";
}
.wsite-social-color .wsite-social-flickr-right:before {
    content: "\e60e";
    color: #ff0084;
}
.wsite-social-square .wsite-social-flickr-right,
.wsite-social-square.wsite-social-flickr-right {
    background-color: #ff0084;
}
.wsite-social-square .wsite-social-flickr-right:after,
.wsite-social-square.wsite-social-flickr-right:after {
    content: "\e60e";
    color: #ffffff;
}
.wsite-social-flickr:before {
    content: "\e608";
}
.wsite-com-product-social-flickr:before {
    content: "\e608";
}
.wsite-social-color .wsite-social-flickr:before {
    content: "\e608";
    color: #0063dc;
}
.wsite-social-square .wsite-social-flickr,
.wsite-social-square.wsite-social-flickr {
    background-color: #0063dc;
}
.wsite-social-square .wsite-social-flickr:after,
.wsite-social-square.wsite-social-flickr:after {
    content: "\e608";
    color: #ffffff;
}
.wsite-social-plus:before {
    content: "\e604";
}
.wsite-com-product-social-plus:before {
    content: "\e604";
}
.wsite-social-color .wsite-social-plus:before {
    content: "\e604";
    color: #dd4b39;
}
.wsite-social-square .wsite-social-plus,
.wsite-social-square.wsite-social-plus {
    background-color: #dd4b39;
}
.wsite-social-square .wsite-social-plus:after,
.wsite-social-square.wsite-social-plus:after {
    content: "\e604";
    color: #ffffff;
}
.wsite-social-instagram:before {
    content: "\e607";
}
.wsite-com-product-social-instagram:before {
    content: "\e607";
}
.wsite-social-color .wsite-social-instagram:before {
    content: "\e607";
    color: #3f729b;
}
.wsite-social-square .wsite-social-instagram,
.wsite-social-square.wsite-social-instagram {
    background-color: #3f729b;
}
.wsite-social-square .wsite-social-instagram:after,
.wsite-social-square.wsite-social-instagram:after {
    content: "\e607";
    color: #ffffff;
}
.wsite-social-linkedin:before {
    content: "\e602";
}
.wsite-com-product-social-linkedin:before {
    content: "\e602";
}
.wsite-social-color .wsite-social-linkedin:before {
    content: "\e602";
    color: #388abe;
}
.wsite-social-square .wsite-social-linkedin,
.wsite-social-square.wsite-social-linkedin {
    background-color: #388abe;
}
.wsite-social-square .wsite-social-linkedin:after,
.wsite-social-square.wsite-social-linkedin:after {
    content: "\e602";
    color: #ffffff;
}
.wsite-social-pinterest:before {
    content: "\e609";
}
.wsite-com-product-social-pinterest:before {
    content: "\e609";
}
.wsite-social-color .wsite-social-pinterest:before {
    content: "\e609";
    color: #cc2127;
}
.wsite-social-square .wsite-social-pinterest,
.wsite-social-square.wsite-social-pinterest {
    background-color: #cc2127;
}
.wsite-social-square .wsite-social-pinterest:after,
.wsite-social-square.wsite-social-pinterest:after {
    content: "\e609";
    color: #ffffff;
}
.wsite-social-rss:before {
    content: "\e605";
}
.wsite-com-product-social-rss:before {
    content: "\e605";
}
.wsite-social-color .wsite-social-rss:before {
    content: "\e605";
    color: #f26522;
}
.wsite-social-square .wsite-social-rss,
.wsite-social-square.wsite-social-rss {
    background-color: #f26522;
}
.wsite-social-square .wsite-social-rss:after,
.wsite-social-square.wsite-social-rss:after {
    content: "\e605";
    color: #ffffff;
}
.wsite-social-tumblr:before {
    content: "\e610";
}
.wsite-com-product-social-tumblr:before {
    content: "\e610";
}
.wsite-social-color .wsite-social-tumblr:before {
    content: "\e610";
    color: #243246;
}
.wsite-social-square .wsite-social-tumblr,
.wsite-social-square.wsite-social-tumblr {
    background-color: #243246;
}
.wsite-social-square .wsite-social-tumblr:after,
.wsite-social-square.wsite-social-tumblr:after {
    content: "\e610";
    color: #ffffff;
}
.wsite-social-twitter:before {
    content: "\e601";
}
.wsite-com-product-social-twitter:before {
    content: "\e601";
}
.wsite-social-color .wsite-social-twitter:before {
    content: "\e601";
    color: #00aced;
}
.wsite-social-square .wsite-social-twitter,
.wsite-social-square.wsite-social-twitter {
    background-color: #00aced;
}
.wsite-social-square .wsite-social-twitter:after,
.wsite-social-square.wsite-social-twitter:after {
    content: "\e601";
    color: #ffffff;
}
.wsite-social-vimeo:before {
    content: "\e60a";
}
.wsite-com-product-social-vimeo:before {
    content: "\e60a";
}
.wsite-social-color .wsite-social-vimeo:before {
    content: "\e60a";
    color: #1ab7ea;
}
.wsite-social-square .wsite-social-vimeo,
.wsite-social-square.wsite-social-vimeo {
    background-color: #1ab7ea;
}
.wsite-social-square .wsite-social-vimeo:after,
.wsite-social-square.wsite-social-vimeo:after {
    content: "\e60a";
    color: #ffffff;
}
.wsite-social-yahoo:before {
    content: "\e60b";
}
.wsite-com-product-social-yahoo:before {
    content: "\e60b";
}
.wsite-social-color .wsite-social-yahoo:before {
    content: "\e60b";
    color: #400191;
}
.wsite-social-square .wsite-social-yahoo,
.wsite-social-square.wsite-social-yahoo {
    background-color: #400191;
}
.wsite-social-square .wsite-social-yahoo:after,
.wsite-social-square.wsite-social-yahoo:after {
    content: "\e60b";
    color: #ffffff;
}
.wsite-social-youtube:before {
    content: "\e606";
}
.wsite-com-product-social-youtube:before {
    content: "\e606";
}
.wsite-social-color .wsite-social-youtube:before {
    content: "\e606";
    color: #b31217;
}
.wsite-social-square .wsite-social-youtube,
.wsite-social-square.wsite-social-youtube {
    background-color: #b31217;
}
.wsite-social-square .wsite-social-youtube:after,
.wsite-social-square.wsite-social-youtube:after {
    content: "\e606";
    color: #ffffff;
}
.wsite-social-color .wsite-social-flickr:before {
    content: "\803";
    color: #0063dc;
}
.wsite-social-color .wsite-social-flickr:after {
    content: "\804";
    color: #ff0084;
}
.wsite-social .wsite-social-item {
    display: inline-block;
}
